import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';
import { Icon } from '@iconify/react';
import { useMediaQuery } from 'react-responsive';
import GamesEdit from './GameEdit';
import { useNavigate } from 'react-router-dom';

const GameProfile = () => {
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [handsToUpdate, setHandsToUpdate] = useState([]);
  const [actionType, setActionType] = useState('');
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 800px)' });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await api.get(`/games/details/${id}`);
        console.log('Game details:', response.data);
        setGame(response.data);
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };

    fetchGameDetails();
  }, [id]);

  const startGame = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        '/game/StartGame',
        { game_id: id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Start game response:', response); // Debug: Log the response
      setLoading(false);
      toast.success('Game started successfully');
    } catch (error) {
      console.error('Error starting game:', error);
      if (error.response && error.response.status === 404) {
        toast.error('The game is already in progress');
      } else {
        toast.error('Error starting the game.');
      }
      setLoading(false);
    }
  };

  const endGame = async () => {
    setLoadingEnd(true);
    try {
      const response = await api.post(
        '/game/EndGame',
        { game_id: id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('End game response:', response); // Debug: Log the response
      setLoadingEnd(false);
      toast.success('Game ended successfully');
    } catch (error) {
      console.error('Error ending game:', error);
      if (error.response && error.response.status === 404) {
        toast.error('The game has already been ended');
      } else {
        toast.error('Error ending the game.');
      }
      setLoadingEnd(false);
    }
  };

  const handleActionClick = (action) => {
    setActionType(action);
    if (action === 'update') {
      const handsToUpdate = game.Hands.map((hand) => ({
        id: hand.id,
        winnerId: '', // initially empty
        user1Id: hand.User1Id,
        user1Name: hand.User1,
        user2Id: hand.User2Id,
        user2Name: hand.User2,
      }));
      setHandsToUpdate(handsToUpdate);
      setModalVisible(true);
    }
  };

  const handleConfirmAction = async () => {
    if (actionType === 'update') {
      try {
        setLoading(true);

        // Filter handsToUpdate to send only selected sets with a winner
        const handsToUpdateFiltered = handsToUpdate.filter((hand) => hand.winnerId !== '');

        const requests = handsToUpdateFiltered.map(async (hand) => {
          const response = await api.post(
            `/hand/update/${hand.id}`,
            { winnerId: parseInt(hand.winnerId) },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          return response.data;
        });
        await Promise.all(requests);
        setLoading(false);
        setModalVisible(false);
        toast.success('Vencedores atualizados com sucesso');
        window.location.reload();
      } catch (error) {
        console.error('Error updating winners:', error);
        toast.error('Error updating the winners of the sets.');
        setLoading(false);
      }
    }
  };

  const handleAction2Click = async (action) => {
    setActionType(action);
    if (action === 'update2') {
      try {
        setLoading(true);
        const response = await api.post(`/games/registerGames/${id}`);
        console.log('Register game response:', response.data);
        setLoading(false);
        toast.success('Jogo atualizado com sucesso');
        window.location.reload();
      } catch (error) {
        console.error('Erro ao registrar jogo:', error);
        toast.error('Erro ao atualizar o jogo.');
        setLoading(false);
      }
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  if (!game) {
    return <div className='flex items-center justify-center h-screen text-white'>Loading...</div>;
  }

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteGames = async () => {
    try {
      await api.delete(`/games/delete/${id}`);
      toast.success('Jogo apagado com sucesso!');
      navigate('/games/list');
      //window.location.reload();
    } catch (error) {
      console.error('Erro ao apagar Jogo:', error);
      toast.error('Erro ao apagar Jogo');
    }
  };

  return (
    <>
      {!isExtraSmallScreen && (
        <>
          <div className='w-full h-full grid grid-cols-2 auto-rows-[400px] gap-5 mx-auto p-4'>
            <div className='relative border overflow-auto border-neutral-700 rounded-xl col-span-1 px-8 py-6'>
              <div className='flex items-center justify-between mb-4'>
                <h1 className='text-3xl font-bold text-white'>Game Details</h1>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>ID:</span> {game.id}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Stream:</span> {game.Stream}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Pontos Jogador 1:</span> {game.P1P}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Pontos Jogador 2:</span> {game.P2P}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vencedor:</span> {game.Winner_name}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Data:</span> {game.Date}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Nome da Liga:</span> {game.League_name}
                  </p>
                </div>
              </div>
              <div className='flex flex-col gap-2 absolute bottom-5 right-3'>
                <button
                  onClick={() => handleAction2Click('update2')}
                  disabled={loading || loadingEnd}
                  className='px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-700 mr-2'
                >
                  Atualizar o jogo
                </button>
                <button
                  onClick={openEditModal}
                  className='px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-700 mr-2'
                >
                  Editar Jogo
                </button>
                <button
                  onClick={handleDeleteGames}
                  className='px-4 py-2 bg-black text-white rounded hover:bg-neutral-900 mr-2'
                >
                  Apagar Jogo
                </button>
              </div>
            </div>

            {isEditModalOpen && (
              <GamesEdit
                leagueId={id}
                onClose={closeEditModal}
                onSave={() => {
                  closeEditModal();
                }}
              />
            )}

            <div className='border border-neutral-700 rounded-xl col-span-1 row-span-2 px-8 py-6'>
              <div className='flex justify-between mb-4'>
                <h2 className='text-lg text-white font-semibold'>Detalhes dos Sets</h2>
                <button
                  onClick={() => handleActionClick('update')}
                  disabled={loading || loadingEnd}
                  className='px-2 py-1 bg-green-500 text-white rounded hover:bg-green-700'
                >
                  {loading ? 'A Atualizar...' : 'Atualizar Vencedor'}
                </button>
              </div>
              <div className='overflow-auto' style={{ height: '725px' }}>
                {game.Hands.length === 0 ? (
                  <p className='text-sm text-white mb-2 justify-center'>Este jogo não tem sets</p>
                ) : (
                  game.Hands.map((hand, index) => (
                    <div key={hand.id} className='mb-4'>
                      <h3 className='text-md text-white font-semibold mb-2'>Set {index + 1}</h3>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>ID:</span> {hand.id}
                      </p>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>Vencedor do set:</span> {hand.winner_name}
                      </p>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>Jogador 1:</span> {hand.User1}
                      </p>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>Jogador 2:</span> {hand.User2}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>

            {modalVisible && (
              <div className='fixed inset-0 bg-neutral-600 bg-opacity-50 flex items-center justify-center'>
                <div className='bg-neutral-800 w-96 rounded-lg pr-3 py-6 pl-6'>
                  <div className='h-96 overflow-auto'>
                    <div className='flex flex-row justify-between items-center mb-4'>
                      <div>
                        <h2 className='text-2xl font-bold mb-4 text-white'>Registar Resultado</h2>
                      </div>
                      <div className='hover:cursor-pointer' onClick={handleModalClose}>
                        <Icon icon='iconamoon:close' width='48' height='48' color='white' />
                      </div>
                    </div>
                    <div>
                      {handsToUpdate.map((hand, index) => (
                        <div key={hand.id} className='mb-4 px-6'>
                          <h3 className='text-md font-semibold mb-2 text-white'>Set {index + 1}</h3>
                          <p className='text-sm text-white mb-2'>
                            <span className='font-semibold'>ID:</span> {hand.id}
                          </p>
                          <label className='block text-sm font-medium text-white mb-1'>
                            Vencedor:
                          </label>
                          <select
                            className='border-gray-300 rounded-md w-full py-2 px-3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            value={hand.winnerId}
                            onChange={(e) =>
                              setHandsToUpdate((prevHands) => {
                                const updatedHands = [...prevHands];
                                updatedHands[index].winnerId = e.target.value;
                                return updatedHands;
                              })
                            }
                          >
                            <option value=''>Select Winner</option>
                            <option value={hand.user1Id}>{hand.user1Name}</option>
                            <option value={hand.user2Id}>{hand.user2Name}</option>
                          </select>
                        </div>
                      ))}
                      <div className='flex justify-end pt-2 mr-4'>
                        <button
                          onClick={handleConfirmAction}
                          className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2'
                        >
                          Confirm
                        </button>
                        <button
                          onClick={() => setModalVisible(false)}
                          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {isExtraSmallScreen && (
        <>
          <div className='w-full h-full grid grid-cols-1 auto-rows-[400px] gap-5 mx-auto p-4'>
            <div className='relative border overflow-auto border-neutral-700 rounded-xl col-span-1 px-8 py-6'>
              <div className='flex items-center justify-between mb-4'>
                <h1 className='text-3xl font-bold text-white'>Game Details</h1>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>ID:</span> {game.id}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Stream:</span> {game.Stream}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Player 1 Points:</span> {game.P1P}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Player 2 Points:</span> {game.P2P}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Winner:</span> {game.Winner_name}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Date:</span> {game.Date}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>League Name:</span> {game.League_name}
                  </p>
                </div>
              </div>
              <div className='flex flex-col gap-2 absolute bottom-5 right-3'>
                <button
                  onClick={() => handleAction2Click('update2')}
                  disabled={loading || loadingEnd}
                  className='px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-700 mr-2'
                >
                  Atualizar o jogo
                </button>
                <button
                  onClick={openEditModal}
                  className='px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-700 mr-2'
                >
                  Editar Jogo
                </button>
                <button
                  onClick={handleDeleteGames}
                  className='px-4 py-2 bg-black text-white rounded hover:bg-neutral-900 mr-2'
                >
                  Apagar Jogo
                </button>
              </div>
            </div>

            {isEditModalOpen && (
              <GamesEdit
                leagueId={id}
                onClose={closeEditModal}
                onSave={() => {
                  closeEditModal();
                }}
              />
            )}

            <div className='border border-neutral-700 rounded-xl col-span-1 row-span-1 px-8 py-6'>
              <div className='flex justify-between mb-4'>
                <h2 className='text-lg text-white font-semibold'>Detalhes dos Sets</h2>
                <button
                  onClick={() => handleActionClick('update')}
                  disabled={loading || loadingEnd}
                  className='px-2 py-1 bg-green-500 text-white rounded hover:bg-green-700'
                >
                  {loading ? 'A Atualizar...' : 'Atualizar Vencedores'}
                </button>
              </div>
              <div className='overflow-auto' style={{ height: '310px' }}>
                {game.Hands.length === 0 ? (
                  <p className='text-sm text-white mb-2 justify-center'>Este jogo não tem sets</p>
                ) : (
                  game.Hands.map((hand, index) => (
                    <div key={hand.id} className='mb-4'>
                      <h3 className='text-md text-white font-semibold mb-2'>Set {index + 1}</h3>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>ID:</span> {hand.id}
                      </p>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>Vencedor do Set:</span> {hand.winner_name}
                      </p>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>Jogador 1:</span> {hand.User1}
                      </p>
                      <p className='text-sm text-white mb-2'>
                        <span className='font-semibold'>Jogador 2:</span> {hand.User2}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>

            {modalVisible && (
              <div className='fixed inset-0 bg-neutral-600 bg-opacity-50 flex items-center justify-center'>
                <div className='bg-neutral-800 w-96 rounded-lg pr-3 py-6 pl-6'>
                  <div className='h-96 overflow-auto'>
                    <div className='flex flex-row justify-between items-center mb-4'>
                      <div>
                        <h2 className='text-2xl font-bold mb-4 text-white'>Registar Resultado</h2>
                      </div>
                      <div className='hover:cursor-pointer' onClick={handleModalClose}>
                        <Icon icon='iconamoon:close' width='48' height='48' color='white' />
                      </div>
                    </div>
                    <div>
                      {handsToUpdate.map((hand, index) => (
                        <div key={hand.id} className='mb-4 px-6'>
                          <h3 className='text-md font-semibold mb-2 text-white'>Set {index + 1}</h3>
                          <p className='text-sm text-white mb-2'>
                            <span className='font-semibold'>ID:</span> {hand.id}
                          </p>
                          <label className='block text-sm font-medium text-white mb-1'>
                            Winner:
                          </label>
                          <select
                            className='border-gray-300 rounded-md w-full py-2 px-3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            value={hand.winnerId}
                            onChange={(e) =>
                              setHandsToUpdate((prevHands) => {
                                const updatedHands = [...prevHands];
                                updatedHands[index].winnerId = e.target.value;
                                return updatedHands;
                              })
                            }
                          >
                            <option value=''>Select Winner</option>
                            <option value={hand.user1Id}>{hand.user1Name}</option>
                            <option value={hand.user2Id}>{hand.user2Name}</option>
                          </select>
                        </div>
                      ))}
                      <div className='flex justify-end pt-2 mr-4'>
                        <button
                          onClick={handleConfirmAction}
                          className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2'
                        >
                          Confirm
                        </button>
                        <button
                          onClick={() => setModalVisible(false)}
                          className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GameProfile;
