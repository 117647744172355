import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';
import countryOptions from '../User/features/nacionalidades';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';
import { updateData } from '../features/auth/authSlice';

const SettingsPage = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    Phone: '',
    Nacionality: '',
    Age: '',
    Password: '',
  });

  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState('');

  useEffect(() => {
    setUserData({
      name: userInfo.name || '',
      email: userInfo.email || '',
      Phone: userInfo.Phone || '',
      Nacionality: userInfo.Nacionality || '',
      Age: userInfo.Age || '',
      Password: '',
    });

    if (userInfo.photo) {
      setPhotoPreview(`https://api.ligas.purplews.live/photos/${userInfo.photo}`);
    }
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
    setPhotoPreview(URL.createObjectURL(file));
  };

  const handleOptionSelect = (value) => {
    setUserData((prevData) => ({ ...prevData, Nacionality: value }));
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('Phone', userData.Phone);
    formData.append('Nacionality', userData.Nacionality);
    formData.append('Age', userData.Age);

    if (userData.Password) {
      formData.append('Password', userData.Password);
    }

    if (photo) {
      formData.append('Photo', photo);
    }

    try {
      await api
        .post(`/user/updateUser/${userInfo.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          dispatch(updateData(res.data.userInfo));
        });
      toast.success('Usuário atualizado com sucesso!');
    } catch (error) {
      toast.error('Erro ao atualizar usuário.');
      console.error('Erro ao atualizar usuário:', error);
    }
  };

  return (
    <div className='flex flex-col w-full h-full p-10 bg-neutral-800'>
      <ToastContainer />
      <div className='flex flex-row text-white items-center gap-2 mb-5'>
        <Icon icon='mdi:cog' width='32' height='32' color='white' />
        <span className='text-xl font-bold'>Configurações de Usuário</span>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-full items-start justify-start space-y-6 text-white'
      >
        <div>
          <label htmlFor='name' className='block text-sm font-medium mb-2'>
            Nome
          </label>
          <input
            type='text'
            id='name'
            name='name'
            value={userData.name}
            onChange={handleChange}
            className='w-full px-4 py-2 border rounded-lg bg-neutral-800 border-neutral-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
            minLength={3}
            pattern='^[a-zA-ZÀ-ÿ ]+$'
            title='Nome deve conter no mínimo 3 caracteres e apenas letras.'
          />
        </div>
        <div>
          <label htmlFor='email' className='block text-sm font-medium mb-2'>
            Email
          </label>
          <input
            type='email'
            id='email'
            name='email'
            value={userData.email}
            onChange={handleChange}
            className='w-full px-4 py-2 border rounded-lg bg-neutral-800 border-neutral-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
            pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]+)?$'
            title='Insira um email válido (ex: exemplo@dominio.com)'
          />
        </div>
        <div>
          <label htmlFor='Phone' className='block text-sm font-medium mb-2'>
            Telefone
          </label>
          <input
            type='tel'
            id='Phone'
            name='Phone'
            value={userData.Phone}
            onChange={handleChange}
            className='w-full px-4 py-2 border rounded-lg bg-neutral-800 border-neutral-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
            pattern='[0-9]{9}'
            title='Formato: 9 números'
            maxLength='9'
            minLength='9'
          />
        </div>
        <div className='flex flex-col items-start justify-start'>
          <label htmlFor='Photo' className='block text-sm font-medium mb-2'>
            Foto
          </label>
          <div className='text-center'>
            <img
              src={
                photoPreview
                  ? photoPreview
                  : `https://api.ligas.purplews.live/photos/` + userInfo.Photo
              }
              alt='Foto do Usuário'
              className='w-32 h-32 object-cover rounded-full mx-auto mb-4 border-4 border-neutral-700'
            />
          </div>
          <input
            type='file'
            id='Photo'
            name='Photo'
            onChange={handlePhotoChange}
            className='w-full px-4 py-2 border rounded-lg bg-neutral-800 border-neutral-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
        </div>
        <div>
          <label htmlFor='Nacionality' className='block text-sm font-medium mb-2'>
            Nacionalidade
          </label>
          <div
            className={`relative ${showDropdown ? 'rounded-t-lg' : 'rounded-lg'} bg-neutral-800`}
          >
            <div
              onClick={toggleDropdown}
              className='cursor-pointer px-4 py-2 flex justify-between items-center border border-neutral-600 rounded-lg'
            >
              <span>{userData.Nacionality || 'Selecione a nacionalidade'}</span>
              <span>{showDropdown ? '▲' : '▼'}</span>
            </div>
            {showDropdown && (
              <div className='absolute top-full left-0 z-10 bg-neutral-800 shadow-lg border border-neutral-600 w-full rounded-b-lg max-h-64 overflow-y-auto'>
                {countryOptions.map((option) => (
                  <div
                    key={option.key}
                    onClick={() => handleOptionSelect(option.value)}
                    className='px-4 py-2 hover:bg-neutral-700 cursor-pointer'
                  >
                    {option.text}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <label htmlFor='Age' className='block text-sm font-medium mb-2'>
            Idade
          </label>
          <input
            type='number'
            id='Age'
            name='Age'
            value={userData.Age}
            onChange={handleChange}
            className='w-full px-4 py-2 border rounded-lg bg-neutral-800 border-neutral-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
            min='16'
            max='90'
          />
        </div>
        <div>
          <label htmlFor='Password' className='block text-sm font-medium mb-2'>
            Senha
          </label>
          <input
            type='password'
            id='Password'
            name='Password'
            value={userData.Password}
            onChange={handleChange}
            className='w-full px-4 py-2 border rounded-lg bg-neutral-800 border-neutral-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
            minLength={8}
            title='Senha deve conter no mínimo 8 caracteres.'
          />
        </div>
        <div className='flex justify-end'>
          <button
            type='submit'
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};

export default SettingsPage;
