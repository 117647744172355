import React from 'react';
import { BarChart, ResponsiveContainer, Tooltip, Bar } from 'recharts';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';

const BarChartBox = (props) => {
  const { color, icon, title, dataKey, linkPath, chartData } = props;

  return (
    <div className='w-full h-full relative'>
      <div className='flex items-center gap-3'>
        <Icon icon={icon} width='32' height='32' color='white' />
        <span>{title}</span>
      </div>
      <Link className='absolute right-0 top-0' style={{ color: color }} to={linkPath}>
        Ver tudo
      </Link>
      <div className=''>
        <ResponsiveContainer width='99%' height={115}>
          <BarChart data={chartData}>
            <Tooltip
              contentStyle={{ background: '#262626', borderRadius: '5px' }}
              labelStyle={{ display: 'none' }}
              cursor={{ fill: 'none' }}
            />
            <Bar dataKey={dataKey} fill={color} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartBox;
