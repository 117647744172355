import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable from '../dataTable/dataTable';
import api from '../../api';
import { leagueList } from '../data/DataComponent';
import { Icon } from '@iconify/react/dist/iconify.js';
import LeagueCreate from './LeagueCreate';
import LeagueEdit from './LeagueEdit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const League = () => {
  const [league, setLeague] = useState([]);
  const [newLeague, setNewLeague] = useState(false);
  const [editLeagueId, setEditLeagueId] = useState(null);
  const [leagueIdToDelete, setLeagueIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await api.get('/league/list');
        let leagues = response.data;

        if (userInfo.type === 'admin') {
          leagues = leagues.filter(
            (league) => league.Users && league.Users.some((user) => user.id === userInfo.id)
          );
        }
        setLeague(leagues);
      } catch (error) {
        console.error('Error fetching leagues:', error);
      }
    };

    fetchLeagues();
  }, [userInfo]);

  const newLeaguePopUp = () => {
    setNewLeague(true);
  };

  const closeLeaguePopUp = () => {
    setNewLeague(false);
  };

  const editLeague = (leagueId) => {
    setEditLeagueId(leagueId);
  };

  const closeEditLeague = () => {
    setEditLeagueId(null);
  };

  const handleSave = () => {
    const fetchLeagues = async () => {
      try {
        const response = await api.get('/league/list');
        let leagues = response.data;

        if (userInfo.type === 'admin') {
          leagues = leagues.filter(
            (league) => league.users && league.users.some((user) => user.id === userInfo.id)
          );
        }

        setLeague(leagues);
      } catch (error) {
        console.error('Error fetching leagues:', error);
      }
    };

    fetchLeagues();
  };

  const confirmDeleteLeague = (leagueId) => {
    setLeagueIdToDelete(leagueId);
    setShowDeleteModal(true);
  };

  const deleteLeague = async () => {
    try {
      await api.delete(`/league/delete/${leagueIdToDelete}`);
      const updatedLeagues = league.filter((item) => item.id !== leagueIdToDelete);
      setLeague(updatedLeagues);
      setShowDeleteModal(false);
      toast.success('Liga deletada com sucesso!');
    } catch (error) {
      console.error('Error deleting league:', error);
      toast.error('Erro ao deletar liga');
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setLeagueIdToDelete(null);
  };

  const onViewLeague = (userId) => {
    navigate(`/league/${userId}`);
  };

  const leagueData = leagueList(league);

  return (
    <div className='flex flex-col w-full h-full p-10 bg-neutral-800'>
      {newLeague && (
        <div className='absolute bg-black opacity-75 inset-0 flex items-center justify-center z-40'>
          <div className='bg-neutral-800 opacity-100 z-50 p-10 rounded-lg'>
            <LeagueCreate onClose={closeLeaguePopUp} />
          </div>
        </div>
      )}

      {editLeagueId && (
        <LeagueEdit leagueId={editLeagueId} onClose={closeEditLeague} onSave={handleSave} />
      )}

      {showDeleteModal && (
        <div className='absolute bg-black bg-opacity-75 inset-0 flex items-center justify-center z-40'>
          <div className='bg-neutral-800 z-50 p-5 rounded-lg w-96'>
            <h2 className='text-white mb-4'>Tem a certeza que deseja apagar esta liga?</h2>
            <div className='flex justify-center'>
              <button
                onClick={deleteLeague}
                className='p-2 mr-5 rounded bg-red-500 hover:bg-red-600 active:bg-red-700 text-white'
              >
                Confirmar
              </button>
              <button
                onClick={cancelDelete}
                className='p-2 ml-5 rounded bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white'
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-row text-white justify-between items-center mb-5'>
        <div className='flex flex-row gap-3 items-center'>
          <Icon icon={leagueData.icon} width='32' height='32' color='white' />
          <span className='text-xl font-bold'>{leagueData.title}</span>
        </div>
        <div>
          <button
            onClick={newLeaguePopUp}
            className='p-3 rounded-lg w-auto bg-blue-600 hover:bg-blue-700 active:bg-blue-800'
          >
            Nova Liga
          </button>
        </div>
      </div>
      <DataTable
        tableHeads={leagueData.tableHeads}
        tableRows={leagueData.tableData}
        onEdit={editLeague}
        onDelete={confirmDeleteLeague}
        onView={onViewLeague}
      />
    </div>
  );
};

export default League;
