import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';

const UserListBox = () => {
  const [users, setUsers] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const [allowedUserTypes, setAllowedUserTypes] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/user/list');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const calculateAllowedUserTypes = () => {
      if (userInfo.roles.includes('ROLE_SUPERADMIN')) {
        setAllowedUserTypes(['1', '2', '3', '4']);
      } else if (userInfo.roles.includes('ROLE_ADMIN')) {
        setAllowedUserTypes(['2', '3', '4']);
      } else {
        setAllowedUserTypes([]);
      }
    };

    calculateAllowedUserTypes();
  }, [userInfo.roles]);

  const getUserTypeValue = (roles) => {
    if (roles.includes('ROLE_SUPERADMIN')) {
      return '1';
    } else if (roles.includes('ROLE_ADMIN')) {
      return '2';
    } else if (roles.includes('ROLE_CAPTAIN')) {
      return '3';
    } else if (roles.includes('ROLE_PLAYER')) {
      return '4';
    } else {
      return '';
    }
  };

  const getUserRoleName = (roles) => {
    if (roles.includes('ROLE_SUPERADMIN')) {
      return 'SuperAdmin';
    } else if (roles.includes('ROLE_ADMIN')) {
      return 'Admin';
    } else if (roles.includes('ROLE_CAPTAIN')) {
      return 'Captain';
    } else if (roles.includes('ROLE_PLAYER')) {
      return 'Player';
    } else {
      return 'UNKNOWN';
    }
  };

  return (
    <div className='text-white w-full h-full relative'>
      <div className='flex flex-row items-center gap-2 mb-5'>
        <Icon icon='mage:users-fill' width='32' height='32' color='white' />
        <span className='text-xl font-bold'>Últimos Registos</span>
      </div>
      <Link className='absolute top-2 right-3' style={{ color: '#FFFFFF' }} to='/users/list'>
        Ver tudo
      </Link>
      <div className='overflow-auto' style={{ height: '480px' }}>
        <div className='p-2'>
          {users
            .filter((user) => allowedUserTypes.includes(getUserTypeValue(user.roles)))
            .sort((a, b) => b.id - a.id)
            .slice(0, 15)
            .map((user) => (
              <div className='flex items-center justify-between mb-7 last:mb-0' key={user.id}>
                <div className='flex gap-5'>
                  <img
                    className='w-10 h-10 rounded-full object-cover'
                    src={`https://api.ligas.purplews.live/photos/` + user.Photo}
                    alt={user.id}
                  />
                  <div className='flex flex-col w-60 gap-1 text-ellipsis'>
                    <span className='text-lg font-bold'>{user.name}</span>
                    <span className='text-sm font-bold'>{user.email}</span>
                  </div>
                </div>
                <span className=''>{getUserRoleName(user.roles)}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserListBox;
