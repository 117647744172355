import { createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../components/features/auth/authSlice';
import { changeSidebar } from '../components/features/layout/layoutSlice';
import { useNavigate, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import useExpanded from '../hooks/useExpanded';
import { useMediaQuery } from 'react-responsive';

const SidebarContext = createContext();

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const user = useSelector((state) => state.auth.userInfo); // Assuming user roles are stored in state.auth.user
  const expanded = useExpanded(946);
  const shrinkBar = useMediaQuery({ query: '(max-width: 946px)' });

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
    dispatch(changeSidebar(false));
  };

  const isSuperAdmin = user?.roles.includes('ROLE_SUPERADMIN');

  return (
    <div
      className={`border-neutral-800 sticky top-20 px-2 py-3 flex flex-col gap-2 h-[calc(100vh-5rem)] select-none overflow-y-auto ${
        expanded ? 'w-64' : ''
      }`}
    >
      {shrinkBar && (
        <>
          <div className='w-full mb-1'>
            <Link to={'/dashboard'}>
              <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='material-symbols:dashboard' width='20' height='20' color='white' />
                {expanded && <span className='text-white'>Dashboard</span>}
              </div>
            </Link>
          </div>

          {isSuperAdmin && (
            <div className='w-full border-t border-neutral-800 pt-3 mb-1'>
              <Link to={'/users/enable'}>
                <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                  <Icon icon='mingcute:user-add-fill' width='20' height='20' color='white' />
                  {expanded && <span className='text-white'>Ativar Usuário</span>}
                </div>
              </Link>
            </div>
          )}

          <div className='w-full border-t border-neutral-800 pt-3 mb-1'>
            <Link to={'/users/list'}>
              <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='mage:users-fill' width='20' height='20' color='white' />
                {expanded && <span className='text-white'>Usuários</span>}
              </div>
            </Link>
          </div>

          <div className='w-full mb-1'>
            <Link to={'/league/list'}>
              <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='mdi:snooker-rack' width='20' height='20' color='white' />
                {expanded && <span className='text-white'>Ligas</span>}
              </div>
            </Link>
          </div>

          <div className='w-full mb-1'>
            <Link to={'/games/list'}>
              <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='icon-park-solid:game-emoji' width='20' height='20' color='white' />
                {expanded && <span className='text-white'>Jogos</span>}
              </div>
            </Link>
          </div>

          <div className='flex flex-col h-full justify-between'>
            <div className='w-full mb-1'>
              <Link to={'/logs/list'}>
                <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                  <Icon icon='icon-park-outline:upload-logs' width='20' height='20' color='white' />
                  {expanded && <span className='text-white'>Logs</span>}
                </div>
              </Link>
            </div>

            <div className='w-full mb-1'>
              <Link to={'/definições'}>
                <div className='flex flex-row items-center gap-4 mx-auto rounded-xl p-3 hover:bg-neutral-800'>
                  <Icon icon='mdi:cog' width='20' height='20' color='white' />
                  {expanded && <span className='text-white'>Definições</span>}
                </div>
              </Link>
            </div>
          </div>
        </>
      )}

      {!shrinkBar && (
        <>
          <div className='w-full mb-1'>
            <Link to={'/dashboard'}>
              <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='material-symbols:dashboard' width='32' height='32' color='white' />
                {expanded && <span className='text-white'>Dashboard</span>}
              </div>
            </Link>
          </div>

          {isSuperAdmin && (
            <div className='w-full border-t border-neutral-800 pt-3 mb-1'>
              <Link to={'/users/enable'}>
                <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                  <Icon icon='mingcute:user-add-fill' width='32' height='32' color='white' />
                  {expanded && <span className='text-white'>Ativar Usuário</span>}
                </div>
              </Link>
            </div>
          )}

          <div className='w-full border-t border-neutral-800 pt-3 mb-1'>
            <Link to={'/users/list'}>
              <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='mage:users-fill' width='32' height='32' color='white' />
                {expanded && <span className='text-white'>Usuários</span>}
              </div>
            </Link>
          </div>

          <div className='w-full mb-1'>
            <Link to={'/league/list'}>
              <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='mdi:snooker-rack' width='32' height='32' color='white' />
                {expanded && <span className='text-white'>Ligas</span>}
              </div>
            </Link>
          </div>

          <div className='w-full mb-1'>
            <Link to={'/games/list'}>
              <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                <Icon icon='icon-park-solid:game-emoji' width='32' height='32' color='white' />
                {expanded && <span className='text-white'>Jogos</span>}
              </div>
            </Link>
          </div>

          <div className='flex flex-col h-full justify-between'>
            <div className='w-full mb-1'>
              <Link to={'/logs/list'}>
                <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                  <Icon icon='icon-park-outline:upload-logs' width='32' height='32' color='white' />
                  {expanded && <span className='text-white'>Logs</span>}
                </div>
              </Link>
            </div>

            <div className='w-full mb-1'>
              <Link to={'/definições'}>
                <div className='flex flex-row items-center gap-4 mx-2 rounded-xl p-3 hover:bg-neutral-800'>
                  <Icon icon='mdi:cog' width='32' height='32' color='white' />
                  {expanded && <span className='text-white'>Definições</span>}
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Sidebar;
