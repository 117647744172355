import React, { useState, useEffect } from 'react';
import { Flag } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import api from '../api';

function LeagueTable(props) {
  const location = useLocation();
  const { selectedLeague } = props;
  const [users, setUsers] = useState([]);

  const fetchUsers = async (leagueId) => {
    try {
      const response = await api.get(`/league/details/${leagueId}`);
      const filteredUsers = response.data.users.filter(
        (user) => user.type === 'captain' || user.type === 'player'
      );
      const sortedUsers = filteredUsers.sort((a, b) => b.W - a.W);
      setUsers(sortedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    if (selectedLeague) {
      fetchUsers(selectedLeague.id);
    }
  }, [selectedLeague]);

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
    }
  };

  return (
    <div className='w-full'>
      <div className='w-full flex flex-row gap-3 bg-transparent text-white text-2xl font-bold py-4 pl-6 items-center'>
        {selectedLeague ? (
          <div className='flex-col items-center content-center justify-center'>
            <div>{selectedLeague.Name}</div>
          </div>
        ) : (
          <div className='flex-col items-center text-neutral-500 select-none content-center justify-center'>
            Escolha uma liga
          </div>
        )}
      </div>

      <table className='table-auto w-full'>
        <thead>
          <tr className='select-none text-white'>
            <th className='px-4 py-3 w-20'>Ranking</th>
            <th className='px-4 py-3 text-start'>Jogador</th>
            <th className='px-4 py-3 w-20'>Jornadas</th>
            <th className='px-4 py-3 w-12'>Pontos</th>
            <th className='px-4 py-3 w-12'>Partidas Disputadas</th>
            <th className='px-4 py-3 w-12'>Partidas Ganhas</th>
            <th className='px-4 py-3 w-12'>Partidas Perdidas</th>
            <th className='px-4 py-3 w-12'>Vitorias Rácio</th>
            <th className='px-4 py-3 w-12'>Derrotas Rácio</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr
              className='odd:bg-neutral-900 odd:text-white even:bg-neutral-800 even:text-white font-bold'
              key={index}
            >
              <td className='px-4 py-3 text-center'>#{index + 1}</td>
              <Link to={`/user/${user.id}`}>
                <td className='px-4 py-3 text-white flex items-center flex-row w-64'>
                  <Flag name={user.Nacionality} />
                  {user.name}
                </td>
              </Link>
              <td className='px-4 py-3 text-center'>{user.jornadas}</td>
              <td className='px-4 py-3 text-center'>{user.pontos}</td>
              <td className='px-4 py-3 text-center'>{user.games}</td>
              <td className='px-4 py-3 text-center'>{user.wins}</td>
              <td className='px-4 py-3 text-center'>{user.loses}</td>
              <td className='px-4 py-3 text-center'>{user.win_ratio}%</td>
              <td className='px-4 py-3 text-center'>{user.lose_ratio}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeagueTable;
