import api from '../../api';
import { useEffect, useState } from 'react';

const countCaptains = (users) => {
  return users.filter((user) => user.roles.includes('ROLE_CAPTAIN')).length;
};

const countPlayers = (users) => {
  return users.filter((user) => user.roles.includes('ROLE_PLAYER')).length;
};

const Data = () => {
  const [users, setUsers] = useState([]);
  const [enableUsers, setEnableUsers] = useState([]);
  const [leagues, setLeague] = useState([]);
  const [games, setGames] = useState([]);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await api.get('/user/list');
        setUsers(usersResponse.data);

        const userEnableResponse = await api.get('/user/isEnable');
        setEnableUsers(userEnableResponse.data);

        const leagueResponse = await api.get('/league/list');
        setLeague(leagueResponse.data);

        const gamesResponse = await api.get('/games/list');
        setGames(gamesResponse.data);

        const logsResponse = await api.get('/logs');
        setLogs(logsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
};

const getWeek = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;

  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const groupUsersByCreationDate = (users) => {
  const groupedData = {};

  users.forEach((user) => {
    const date = new Date(user.creation_date);
    const year = date.getFullYear();
    const week = getWeek(date);
    const key = `${year}-W${week}`;

    if (!groupedData[key]) {
      groupedData[key] = 0;
    }
    groupedData[key] += 1;
  });

  // Ordenar e limitar a 12 semanas
  const sortedWeeks = Object.keys(groupedData)
    .sort(
      (a, b) =>
        new Date(a.split('-W')[0], 0, 1 + (a.split('-W')[1] - 1) * 7) -
        new Date(b.split('-W')[0], 0, 1 + (b.split('-W')[1] - 1) * 7)
    )
    .slice(-12);

  return sortedWeeks.map((week) => ({ name: week, Usuários: groupedData[week] }));
};

const calculatePercentageChange = (current, previous) => {
  if (previous === 0) return current >= 0 ? 100 : -100;

  let percentageChange = ((current - previous) / previous) * 100;
  // Limitar o valor máximo a ±100
  percentageChange = Math.min(Math.max(percentageChange, -100), 100);

  return Math.floor(percentageChange);
};

const chartBoxUser = (users) => {
  const chartData = groupUsersByCreationDate(users);

  let previousWeekCount = 0;
  let currentWeekCount = 0;

  if (chartData.length > 1) {
    currentWeekCount = chartData[chartData.length - 1].Usuários;
    previousWeekCount = chartData[chartData.length - 2].Usuários;
  } else if (chartData.length === 1) {
    currentWeekCount = chartData[0].Usuários;
  }

  const percentageChange = Math.floor(
    calculatePercentageChange(currentWeekCount, previousWeekCount)
  );

  return {
    color: '#8884d8',
    icon: 'mage:users-fill',
    title: 'Total de Usuários',
    linkPath: '/users/list',
    number: users.length,
    dataKey: 'Usuários',
    percentage: percentageChange,
    chartData: chartData,
  };
};

const groupLeaguesByCreationDate = (leagues) => {
  const groupedData = {};

  leagues.forEach((league) => {
    const date = new Date(league.creation_date);
    const year = date.getFullYear();
    const week = getWeek(date);
    const key = `${year}-W${week}`;

    if (!groupedData[key]) {
      groupedData[key] = 0;
    }
    groupedData[key] += 1;
  });

  // Ordenar e limitar a 12 semanas
  const sortedWeeks = Object.keys(groupedData)
    .sort(
      (a, b) =>
        new Date(a.split('-W')[0], 0, 1 + (a.split('-W')[1] - 1) * 7) -
        new Date(b.split('-W')[0], 0, 1 + (b.split('-W')[1] - 1) * 7)
    )
    .slice(-12);

  return sortedWeeks.map((week) => ({ name: week, Ligas: groupedData[week] }));
};

const chartBoxLeague = (leagues) => {
  const chartData = groupLeaguesByCreationDate(leagues);

  let previousWeekCount = 0;
  let currentWeekCount = 0;

  if (chartData.length > 1) {
    currentWeekCount = chartData[chartData.length - 1].Ligas;
    previousWeekCount = chartData[chartData.length - 2].Ligas;
  } else if (chartData.length === 1) {
    currentWeekCount = chartData[0].Ligas;
  }

  const percentageChange = Math.floor(
    calculatePercentageChange(currentWeekCount, previousWeekCount)
  );

  return {
    color: 'skyblue',
    icon: 'mdi:snooker-rack',
    title: 'Total de Ligas',
    linkPath: '/league/list',
    number: leagues.length,
    dataKey: 'Ligas',
    percentage: percentageChange,
    chartData: chartData,
  };
};

const groupGamesByCreationDate = (games) => {
  const groupedData = {};

  games.forEach((game) => {
    const date = new Date(game.creation_date);
    const year = date.getFullYear();
    const week = getWeek(date);
    const key = `${year}-W${week}`;

    if (!groupedData[key]) {
      groupedData[key] = 0;
    }
    groupedData[key] += 1;
  });

  const sortedWeeks = Object.keys(groupedData)
    .sort(
      (a, b) =>
        new Date(a.split('-W')[0], 0, 1 + (a.split('-W')[1] - 1) * 7) -
        new Date(b.split('-W')[0], 0, 1 + (b.split('-W')[1] - 1) * 7)
    )
    .slice(-12);

  return sortedWeeks.map((week) => ({ name: week, Jogos: groupedData[week] }));
};

const chartBoxGames = (games) => {
  const chartData = groupGamesByCreationDate(games);

  let previousWeekCount = 0;
  let currentWeekCount = 0;

  if (chartData.length > 1) {
    currentWeekCount = chartData[chartData.length - 1].Jogos;
    previousWeekCount = chartData[chartData.length - 2].Jogos;
  } else if (chartData.length === 1) {
    currentWeekCount = chartData[0].Jogos;
  }

  const percentageChange = Math.floor(
    calculatePercentageChange(currentWeekCount, previousWeekCount)
  );

  return {
    color: 'teal',
    icon: 'icon-park-solid:game-emoji',
    title: 'Total de Jogos',
    linkPath: '/games/list',
    number: games.length,
    dataKey: 'Jogos',
    percentage: percentageChange,
    chartData: chartData,
  };
};

const matchBoxGames = (games) => ({
  color: 'teal',
  icon: 'icon-park-solid:game-emoji',
  title: 'Próximos Jogos',
  listTitle: 'Total de Jogos',
  linkPath: '/games/list',
  chartData: games
    ? games.map((game) => ({
        id: game.id,
        Location: game.Location,
        Stream: game.Stream,
        P1P: game.P1P,
        P2P: game.P2P,
        Date: game.Date,
        LeagueID: game.League_id,
        LeagueName: game.League_name,
        Winner_name: game.Winner_name,
        Hands: game.Hands
          ? game.Hands.map((hand) => ({
              id: hand.id,
              User1: {
                id: hand.User1.id,
                name: hand.User1.name,
                email: hand.User1.email,
                Phone: hand.User1.Phone,
                Photo: hand.User1.Photo,
                Nacionality: hand.User1.Nacionality,
                Age: hand.User1.Age,
                creation_date: hand.User1.creation_date,
              },
              User2: {
                id: hand.User2.id,
                name: hand.User2.name,
                email: hand.User2.email,
                Phone: hand.User2.Phone,
                Photo: hand.User2.Photo,
                Nacionality: hand.User2.Nacionality,
                Age: hand.User2.Age,
                creation_date: hand.User2.creation_date,
              },
            }))
          : [],
        Users: game.Users
          ? game.Users.map((user) => ({
              id: user.id,
              name: user.name,
              email: user.email,
              Phone: user.Phone,
              Photo: user.Photo,
              roles: user.roles,
              type: user.type,
              W: user.W,
              L: user.L,
              F: user.F,
              Points: user.Points,
              Nacionality: user.Nacionality,
              Age: user.Age,
              Password: user.Password,
              colors: user.colors,
              creation_date: user.creation_date,
              games: user.games,
              win_ratio: user.win_ratio,
              lose_ratio: user.lose_ratio,
              jornadas: user.jornadas,
            }))
          : [],
      }))
    : [],
  tableHeads: [
    { field: 'id', headerName: 'ID', headerWidth: 80 },
    { field: 'Location', headerName: 'Localização', headerWidth: 150 },
    { field: 'Stream', headerName: 'Transmissão', headerWidth: 150 },
    { field: 'Player1', headerName: 'Jogador 1', headerWidth: 150 },
    { field: 'Player2', headerName: 'Jogador 2', headerWidth: 150 },
    { field: 'P1P', headerName: 'Pontos J1', headerWidth: 100 },
    { field: 'P2P', headerName: 'Pontos J2', headerWidth: 100 },
    { field: 'winner_id', headerName: 'Vencedor', headerWidth: 150 },
    { field: 'Date', headerName: 'Data', headerWidth: 150 },
    { field: 'League_id', headerName: 'Nome da Liga', headerWidth: 150 },
  ],
  tableData: games
    ? games.map((game) => ({
        id: game.id,
        Location: game.Location,
        Stream: game.Stream,
        Player1: game.Hands && game.Hands.length > 0 ? game.Hands[0].User1.name : '',
        Player2: game.Hands && game.Hands.length > 0 ? game.Hands[0].User2.name : '',
        P1P: game.P1P,
        P2P: game.P2P,
        winner_id: game.Winner_name,
        Date: game.Date,
        League_id: game.League_name,
      }))
    : [],
});

const logsList = (logs) => ({
  color: '#FFFFFF',
  icon: 'icon-park-outline:upload-logs',
  title: 'Listagem de Logs',
  linkPath: '/logs/list',
  tableHeads: [
    { field: 'id', headerName: 'ID', headerWidth: 125 },
    { field: 'user', headerName: 'Usuário', headerWidth: 250 },
    { field: 'action', headerName: 'Ação', headerWidth: 300 },
    { field: 'date', headerName: 'Data', headerWidth: 250 },
    { field: 'details', headerName: 'Detalhes', headerWidth: 400 },
  ],
  tableData: logs
    ? logs.map((log, index) => ({
        id: log.id || index,
        user: log.user,
        action: log.action,
        date: log.date,
        details: log.details,
      }))
    : [],
});

const userList = (users) => ({
  color: '#8884d8',
  icon: 'mage:users-fill',
  title: 'Total de Usuários',
  linkPath: '/users/list',
  tableHeads: [
    { field: 'id', headerName: 'ID', headerWidth: 80 },
    { field: 'name', headerName: 'Nome', headerWidth: 250 },
    { field: 'email', headerName: 'E-mail', headerWidth: 325 },
    { field: 'Phone', headerName: 'Telemóvel', headerWidth: 175 },
    { field: 'type', headerName: 'Tipo', headerWidth: 175 },
    { field: 'Age', headerName: 'Idade', headerWidth: 110 },
    { field: 'winRatioGeral', headerName: 'W/L', headerWidth: 110 },
    { field: 'Jogos', headerName: 'Jogos', headerWidth: 110 },
  ],
  tableData: users
    ? users.map((user, index) => ({
        id: user.id || index,
        name: user.name,
        email: user.email,
        Phone: user.Phone,
        type: user.type,
        Age: user.Age,
        winRatioGeral: `${user.winRatioGeral}%`,
        Jogos: user.Jogos,
      }))
    : [],
});

const enableUserList = (enableUsers) => ({
  color: '#8884d8',
  icon: 'mage:users-fill',
  title: 'Ativar Usuário',
  linkPath: '/users/enable',
  tableHeads: [
    { field: 'id', headerName: 'ID', headerWidth: 80 },
    { field: 'name', headerName: 'Nome', headerWidth: 250 },
    { field: 'email', headerName: 'E-mail', headerWidth: 325 },
    { field: 'Phone', headerName: 'Telemóvel', headerWidth: 175 },
    { field: 'type', headerName: 'Tipo', headerWidth: 175 },
    { field: 'Age', headerName: 'Idade', headerWidth: 110 },
  ],
  tableData: enableUsers
    ? enableUsers.map((enableUsers, index) => ({
        id: enableUsers.id || index,
        name: enableUsers.name,
        email: enableUsers.email,
        Phone: enableUsers.Phone,
        type: enableUsers.type,
        Age: enableUsers.Age,
      }))
    : [],
});

const leagueList = (leagues) => ({
  color: '#8884d8',
  icon: 'mdi:snooker-rack',
  title: 'Total de Ligas',
  linkPath: '/league/list',
  tableHeads: [
    { field: 'id', headerName: 'ID', headerWidth: 80 },
    { field: 'Name', headerName: 'Nome', headerWidth: 200 },
    { field: 'Availability', headerName: 'Inscrições', headerWidth: 200 },
    { field: 'Slots', headerName: 'Vagas', headerWidth: 125 },
    { field: 'SlotsFilled', headerName: 'Vagas Ocupadas', headerWidth: 150 },
    { field: 'SlotsAvailable', headerName: 'Vagas Disponiveis', headerWidth: 150 },
    { field: 'start_date', headerName: 'Início', headerWidth: 200 },
    { field: 'end_date', headerName: 'Fim', headerWidth: 225 },
  ],
  tableData: leagues
    ? leagues.map((league, index) => ({
        id: league.id || index,
        Name: league.Name,
        Availability: league.Availability,
        Slots: league.Slots,
        SlotsFilled: league.SlotsFilled,
        SlotsAvailable: league.SlotsAvailable,
        start_date: league.start_date,
        end_date: league.end_date,
      }))
    : [],
});

const userTypeChart = (users) => ({
  color: '#8884d8',
  icon: 'mage:users-fill',
  title: 'Tipos de Usuários',
  linkPath: '/users/list',
  number: countCaptains(users) + countPlayers(users),
  dataKey: 'value',
  percentage: 45,
  chartData: [
    { name: 'Total de Capitães', value: countCaptains(users), fill: '#8bf8a7' },
    { name: 'Total de Jogadores', value: countPlayers(users), fill: '#bdabc4' },
  ],
});

export {
  chartBoxGames,
  chartBoxLeague,
  chartBoxUser,
  userTypeChart,
  matchBoxGames,
  logsList,
  userList,
  leagueList,
  enableUserList,
};

export default Data;
