import React from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';

const DataTable = ({
  tableHeads = [],
  tableRows = [],
  onDelete,
  onEdit,
  showActions = true,
  onView,
}) => {
  const user = useSelector((state) => state.auth.userInfo);

  const isSuperAdmin = user.roles.includes('ROLE_SUPERADMIN');
  const isAdmin = user.roles.includes('ROLE_ADMIN');

  const columns = tableHeads.map((head) => ({
    field: head.field,
    headerName: head.headerName,
    flex: head.headerFlex || 1, // Use flex instead of fixed width for responsiveness
  }));

  if (showActions && (isAdmin || isSuperAdmin)) {
    columns.push({
      field: 'actions',
      headerName: '',
      flex: 1,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <div className='flex gap-2 justify-end w-full'>
          <button
            onClick={() => onView(params.row.id)}
            className='p-2 rounded bg-green-500 hover:bg-green-600 active:bg-green-700 text-white'
          >
            <Icon icon='mdi:eye' width='15' height='15' />
          </button>
          {isSuperAdmin && (
            <>
              <button
                onClick={() => onEdit(params.row.id)}
                className='p-2 rounded bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white'
              >
                <Icon icon='mdi:pencil' width='15' height='15' />
              </button>
              <button
                onClick={() => onDelete(params.row.id)}
                className='p-2 rounded bg-red-500 hover:bg-red-600 active:bg-red-700 text-white'
              >
                <Icon icon='mdi:delete' width='15' height='15' />
              </button>
            </>
          )}
        </div>
      ),
    });
  }

  const rows = tableRows.map((row, index) => ({
    id: row.id || index,
    ...row,
  }));

  return (
    <Grid container spacing={2} style={{ height: '100%', width: '100%' }}>
      <Grid item xs={12}>
        <div style={{ height: 631, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
            sx={{
              backgroundColor: '#262626', // Grid container background
              borderColor: '#404040',
              '& .MuiDataGrid-cell': {
                // Cell styles
                backgroundColor: '#262626',
                color: 'white',
                borderColor: '#404040',
              },
              '& .MuiDataGrid-columnHeaders': {
                // Header row styles
                backgroundColor: '#171717',
                color: 'white',
                borderColor: '#404040',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                // Header title styles
                fontWeight: 'bold',
                borderColor: '#404040',
              },
              '& .MuiDataGrid-footerContainer': {
                // Footer styles
                backgroundColor: '#171717',
                color: 'white',
                borderColor: '#404040',
              },
              '& .MuiDataGrid-row': {
                // Row styles
                '&:hover': {
                  backgroundColor: '#444444', // Hover state for rows
                  borderColor: '#404040',
                },
                '&.Mui-selected': {
                  backgroundColor: '#555555', // Selected row state
                  borderColor: '#404040',
                },
              },
              '& .MuiDataGrid-checkboxInput': {
                // Checkbox styles
                color: 'white',
                borderColor: '#404040',
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default DataTable;
