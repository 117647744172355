const slotsOptions = [];

for (let i = 8; i <= 60; i += 2) {
  slotsOptions.push({
    key: `${i}`,
    value: `${i}`,
    text: (
      <>
        <i className={i.toString()} />
        {i}
      </>
    ),
  });
}

export default slotsOptions;
