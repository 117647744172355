import React, { useState, useEffect } from 'react';
import api from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeagueEdit = ({ leagueId, onClose, onSave }) => {
  const [leagueData, setLeagueData] = useState({
    Name: '',
    Slots: '',
    Availability: '',
    Start_date: '',
    End_date: '',
  });
  const [modifiedFields, setModifiedFields] = useState({});

  useEffect(() => {
    const fetchLeague = async () => {
      try {
        const response = await api.get(`/league/update/${leagueId}`);
        setLeagueData({
          Name: response.data.Name,
          Slots: response.data.Slots,
          Availability: response.data.Availability,
          Start_date: response.data.Start_date,
          End_date: response.data.End_date,
        });
      } catch (error) {
        console.error('Error fetching league data:', error);
      }
    };

    fetchLeague();
  }, [leagueId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeagueData((prevData) => ({ ...prevData, [name]: value }));
    setModifiedFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/league/update/${leagueId}`, JSON.stringify(modifiedFields), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      onSave();
      onClose();
      toast.success('Liga atualizada com sucesso!');
    } catch (error) {
      console.error('Error updating league:', error);
      toast.error('Erro ao atualizar liga');
    }
  };

  return (
    <div className='absolute bg-black opacity-75 inset-0 flex items-center justify-center z-40'>
      <div className='bg-neutral-800 opacity-100 z-50 p-10 rounded-lg'>
        <h2 className='text-xl font-bold text-white mb-4'>Editar Liga</h2>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Name'>
              Nome
            </label>
            <input
              type='text'
              id='Name'
              name='Name'
              value={leagueData.Name}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Availability'>
              Inscrições
            </label>
            <select
              id='Availability'
              name='Availability'
              value={leagueData.Availability}
              onChange={handleChange}
              className='w-full p-2 rounded'
            >
              <option value='' disabled>
                Selecione a Localização
              </option>
              <option value='1'>Abertas</option>
              <option value='0'>Fechadas</option>
            </select>
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Slots'>
              Slots
            </label>
            <input
              type='number'
              id='Slots'
              name='Slots'
              value={leagueData.Slots}
              onChange={handleChange}
              className='w-full p-2 rounded'
              min='16'
              max='90'
            />
          </div>

          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Start_date'>
              Data de Início
            </label>
            <input
              type='datetime-local'
              id='Start_date'
              name='Start_date'
              value={leagueData.Start_date}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='End_date'>
              Data de Término
            </label>
            <input
              type='datetime-local'
              id='End_date'
              name='End_date'
              value={leagueData.End_date}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='flex justify-end'>
            <button
              type='button'
              onClick={onClose}
              className='p-2 rounded bg-gray-500 hover:bg-gray-600 text-white mr-2'
            >
              Cancelar
            </button>
            <button type='submit' className='p-2 rounded bg-blue-500 hover:bg-blue-600 text-white'>
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeagueEdit;
