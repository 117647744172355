import React, { useState } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countryOptions from './User/features/nacionalidades';
import colorOptions from './User/features/cores';

function RegisterForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [nationality, setNationality] = useState('');
  const [age, setAge] = useState('');
  const [colors, setColors] = useState('');
  const [Photo, setPhoto] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('phone', phone);
    formData.append('nationality', nationality);
    formData.append('age', age);
    formData.append('Photo', Photo);
    formData.append('colors', colors);

    try {
      await api.post('/register', formData);
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer o registro:', error);
      toast.error('Login.register_failed');
    }
  };

  const handleLogIn = () => {
    navigate('/login');
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white'>
      <div className='max-w-md w-full bg-gray-800 rounded-lg shadow-lg p-8 space-y-6'>
        <h2 className='text-center text-3xl font-extrabold text-white'>Register to proceed</h2>
        <form onSubmit={handleSubmit} className='space-y-4'>
          <div>
            <label htmlFor='name' className='block font-semibold text-white'>
              Nome:
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
              minLength={3}
              pattern='^[a-zA-ZÀ-ÿ ]+$'
              title='Nome deve conter no mínimo 3 caracteres e apenas letras.'
            />
          </div>
          <div>
            <label htmlFor='email' className='block font-semibold text-white'>
              Email:
            </label>
            <input
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
              pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]+)?$'
              title='Insira um email válido (ex: exemplo@dominio.com)'
            />
          </div>
          <div>
            <label htmlFor='password' className='block font-semibold text-white'>
              Senha:
            </label>
            <input
              type='password'
              id='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
              minLength={8}
              pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w!?._]{8,}$'
              title='Senha deve conter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula e um número.'
            />
          </div>
          <div>
            <label htmlFor='Photo' className='block font-semibold text-white'>
              Foto:
            </label>
            <input
              type='file'
              id='Photo'
              name='Photo'
              onChange={handlePhotoChange}
              className='mt-1 p-2 border bg-white border-gray-300 rounded-md w-full'
              required
            />
          </div>
          <div>
            <label htmlFor='phone' className='block font-semibold text-white'>
              Telefone:
            </label>
            <input
              type='tel'
              id='phone'
              name='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              pattern='[0-9]{9}'
              title='Formato: 9 números'
              maxLength='9'
              minLength='9'
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
            />
          </div>
          <div>
            <label htmlFor='nationality' className='block font-semibold text-white'>
              Nacionalidade:
            </label>
            <select
              id='nationality'
              name='nationality'
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione a nacionalidade
              </option>
              {countryOptions.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='colors' className='block font-semibold text-white'>
              Cores:
            </label>
            <select
              id='colors'
              name='colors'
              value={colors}
              onChange={(e) => setColors(e.target.value)}
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione a sua cor
              </option>
              {colorOptions.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='age' className='block font-semibold text-white'>
              Idade:
            </label>
            <input
              type='number'
              id='age'
              name='age'
              value={age}
              onChange={(e) => setAge(e.target.value)}
              min='16'
              max='90'
              className='mt-1 p-2 border border-gray-800 rounded-md w-full'
              required
            />
          </div>
          <div>
            <button
              type='submit'
              className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Sign in
            </button>
          </div>
          <div
            onClick={handleLogIn}
            className='text-center text-white hover:text-gray-300 hover:cursor-pointer'
          >
            Already have an account? Log in.
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
