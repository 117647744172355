// Logs.js
import React, { useEffect, useState } from 'react';
import DataTable from '../dataTable/dataTable';
import api from '../../api';
import { logsList } from '../data/DataComponent';
import { Icon } from '@iconify/react/dist/iconify.js';

const Logs = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await api.get('/logs');
        setLogs(response.data);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
  }, []);

  const logData = logsList(logs);

  return (
    <div className='flex flex-col w-full h-full p-10 bg-neutral-800'>
      <div className='flex flex-row text-white items-center gap-2 mb-5'>
        <Icon icon={logData.icon} width='32' height='32' color='white' />
        <span className='text-xl font-bold'>{logData.title}</span>
      </div>
      <DataTable
        tableHeads={logData.tableHeads}
        tableRows={logData.tableData}
        showActions={false} // Passando false para esconder os botões de ação
      />
    </div>
  );
};

export default Logs;
