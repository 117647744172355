import React, { useState, useEffect } from 'react';
import api from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GamesEdit = ({ gameId, onClose, onSave }) => {
  const [gameData, setGameData] = useState({
    Location: '',
    Stream: '',
    P1P: '',
    P2P: '',
    Date: '',
    Winner: '',
    league: '',
  });
  const [modifiedFields, setModifiedFields] = useState({});

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const response = await api.get(`/games/update/${gameId}`);
        const { Location, Stream, P1P, P2P, Date, Winner, league } = response.data;
        setGameData({
          Location,
          Stream,
          P1P,
          P2P,
          Date,
          Winner,
          league,
        });
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    };

    fetchGame();
  }, [gameId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGameData((prevData) => ({ ...prevData, [name]: value }));
    setModifiedFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const formattedDate = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(
      d.getDate()
    ).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(
      2,
      '0'
    )}:${String(d.getSeconds()).padStart(2, '0')}`;
    return formattedDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = { ...modifiedFields };
    if (modifiedFields.Date) {
      formattedData.Date = formatDate(modifiedFields.Date);
    }
    try {
      await api.put(`/games/update/${gameId}`, JSON.stringify(formattedData), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      onSave();
      onClose();
      toast.success('Jogo atualizado com sucesso!');
    } catch (error) {
      console.error('Error updating game:', error);
      toast.error('Erro ao atualizar jogo');
    }
  };

  return (
    <div className='absolute bg-black opacity-80 inset-0 flex items-center justify-center z-40'>
      <div className='bg-neutral-800 opacity-100 z-50 p-10 rounded-lg w-auto'>
        <h2 className='text-xl font-bold text-white mb-4'>Editar Jogo</h2>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Location'>
              Localização
            </label>
            <input
              type='text'
              id='Location'
              name='Location'
              value={gameData.Location}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Stream'>
              Stream
            </label>
            <input
              type='text'
              id='Stream'
              name='Stream'
              value={gameData.Stream}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='P1P'>
              P1P
            </label>
            <input
              type='text'
              id='P1P'
              name='P1P'
              value={gameData.P1P}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='P2P'>
              P2P
            </label>
            <input
              type='text'
              id='P2P'
              name='P2P'
              value={gameData.P2P}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Date'>
              Data
            </label>
            <input
              type='datetime-local'
              id='Date'
              name='Date'
              value={gameData.Date}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Winner'>
              Vencedor
            </label>
            <input
              type='text'
              id='Winner'
              name='Winner'
              value={gameData.Winner}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='league'>
              Liga
            </label>
            <input
              type='text'
              id='league'
              name='league'
              value={gameData.league}
              onChange={handleChange}
              className='w-full p-2 rounded'
            />
          </div>
          <div className='flex justify-end'>
            <button
              type='button'
              onClick={onClose}
              className='p-2 rounded bg-gray-500 hover:bg-gray-600 text-white mr-2'
            >
              Cancelar
            </button>
            <button type='submit' className='p-2 rounded bg-blue-500 hover:bg-blue-600 text-white'>
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GamesEdit;
