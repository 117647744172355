import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GameCreate = ({ onClose }) => {
  const [formData, setFormData] = useState({
    Location: '',
    Stream: '',
    Date: '',
    UserIds: ['', ''],
  });

  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/user/list');
        setUsers(response.data);
      } catch (error) {
        console.error('Erro ao buscar lista de usuários:', error);
        toast.error('Erro ao buscar lista de usuários');
      }
    };

    const fetchLocations = async () => {
      try {
        const response = await api.get('/location/list');
        setLocations(response.data);
      } catch (error) {
        console.error('Erro ao buscar lista de localizações:', error);
        toast.error('Erro ao buscar lista de localizações');
      }
    };

    fetchUsers();
    fetchLocations();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUser1Change = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      UserIds: [value, formData.UserIds[1]],
    });
  };

  const handleUser2Change = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      UserIds: [formData.UserIds[0], value],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.UserIds[0] === '' || formData.UserIds[1] === '') {
      toast.error('Selecione dois usuários para criar um jogo.');
      return;
    }

    try {
      const formDataToSend = {
        Location: formData.Location,
        Stream: formData.Stream || '',
        Date: formData.Date || '',
        UserIds: formData.UserIds,
      };

      await api.post('/games/create', formDataToSend);
      setFormSubmitted(true);
      setFormData({
        Location: '',
        Stream: '',
        Date: '',
        UserIds: ['', ''],
      });

      toast.success('Jogo criado com sucesso!');
    } catch (error) {
      console.error('Erro ao criar jogo:', error);
      toast.error('Erro ao criar jogo');
    }
  };

  return (
    <div className='w-96 mx-auto p-10 bg-neutral-800 rounded-2xl shadow-lg'>
      <div className='flex flex-row justify-between items-center'>
        <div>
          <h2 className='text-4xl font-bold mb-4 text-white'>Criar Jogo</h2>
        </div>
        <div className='hover:cursor-pointer' onClick={onClose}>
          <Icon icon='iconamoon:close' width='48' height='48' color='white' />
        </div>
      </div>
      {formSubmitted ? (
        <div className='text-white'>Jogo criado com sucesso!</div>
      ) : (
        <form onSubmit={handleSubmit} className='space-y-4'>
          <div>
            <label htmlFor='Localizacao' className='block font-semibold text-white'>
              Localização:
            </label>
            <select
              id='Localizacao'
              name='Location'
              value={formData.Location}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione a Localização
              </option>
              {locations.map((location) => (
                <option key={location.id} value={location.Name}>
                  {location.Name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='User1' className='block font-semibold text-white'>
              Selecionar Jogador 1:
            </label>
            <select
              id='User1'
              name='User1'
              value={formData.UserIds[0]}
              onChange={handleUser1Change}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione o Jogador 1:
              </option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.id}. {user.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='User2' className='block font-semibold text-white'>
              Selecionar Jogador 2:
            </label>
            <select
              id='User2'
              name='User2'
              value={formData.UserIds[1]}
              onChange={handleUser2Change}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione o Jogador 2
              </option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.id}. {user.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='Transmissão' className='block font-semibold text-white'>
              Transmissão:
            </label>
            <input
              type='text'
              id='Stream'
              name='Stream'
              value={formData.Stream}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            />
          </div>
          <div>
            <label htmlFor='Date' className='block font-semibold text-white'>
              Horário:
            </label>
            <input
              type='datetime-local'
              id='Date'
              name='Date'
              value={formData.Date}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            />
          </div>
          <div>
            <button
              type='submit'
              className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'
            >
              Criar Jogo
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default GameCreate;
