import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colorOptions from './User/features/cores';
import countryOptions from './User/features/nacionalidades';

const AdminRegister = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    nationality: '',
    age: '',
    colors: '',
    Photo: null,
    leagueLocation: '',
  });

  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({ ...prevData, Photo: e.target.files[0] }));
  };

  const handleOptionSelect = (value) => {
    setFormData({
      ...formData,
      nationality: value,
    });
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await api.post('/createAdmin', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 201) {
        toast.success('Administrador registrado com sucesso!');
        navigate('/adminregister/accept');
      } else {
        toast.error('Erro ao registrar administrador');
      }
    } catch (error) {
      console.error('Error registering admin:', error);
      toast.error('Erro ao registrar administrador');
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className='flex flex-col h-screen content-center justify-center items-center'>
      <h2 className='text-white mb-4'>Registrar Administrador</h2>
      <form onSubmit={handleSubmit} className='space-y-4'>
        <div>
          <label className='text-white mb-2'>Nome</label>
          <input
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
            minLength={3}
            pattern='^[a-zA-ZÀ-ÿ ]+$'
            title='Nome deve conter no mínimo 3 caracteres e apenas letras.'
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <label className='text-white mb-2'>Email</label>
          <input
            type='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
            pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]+)?$'
            title='Insira um email válido (ex: exemplo@dominio.com)'
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <label className='text-white mb-2'>Password</label>
          <input
            type='password'
            name='password'
            value={formData.password}
            onChange={handleChange}
            required
            minLength={8}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w!?._]{8,}$'
            title='Senha deve conter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula e um número.'
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <label className='text-white mb-2'>Telefone</label>
          <input
            type='text'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            required
            pattern='[0-9]{9}'
            title='Formato: 9 números'
            maxLength='9'
            minLength='9'
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <label htmlFor='nationality' className='block font-semibold text-white'>
            Nacionalidade:
          </label>
          <div
            className={`relative mt-1 bg-white rounded-t-md w-full ${
              showDropdown ? '' : 'rounded-b-md'
            }`}
          >
            <div
              onClick={toggleDropdown}
              className='cursor-pointer appearance-none p-2 bg-transparent w-full flex justify-between items-center'
            >
              <span>{formData.nationality || 'Selecione a nacionalidade'}</span>
              <span>{showDropdown ? '▲' : '▼'}</span>
            </div>
            {showDropdown && (
              <div className='absolute top-full left-0 z-10 bg-white shadow-lg border border-gray-300 overflow-y-auto w-full h-64 rounded-b-md'>
                {countryOptions.map((option) => (
                  <div
                    key={option.key}
                    onClick={() => handleOptionSelect(option.value)}
                    className='p-2 hover:bg-gray-200 cursor-pointer'
                  >
                    {option.text}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <label className='text-white mb-2'>Idade</label>
          <input
            type='number'
            name='age'
            value={formData.age}
            onChange={handleChange}
            required
            min='16'
            max='90'
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <label htmlFor='colors' className='block font-semibold text-white'>
            Cores:
          </label>
          <select
            id='colors'
            name='colors'
            value={formData.colors}
            onChange={handleChange}
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
          >
            <option value='' disabled>
              Selecione a sua cor
            </option>
            {colorOptions.map((option) => (
              <option key={option.key} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className='text-white mb-2'>Foto</label>
          <input
            type='file'
            name='photo'
            onChange={handleFileChange}
            required
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <label className='text-white mb-2'>Localização da liga (nome do café)</label>
          <input
            type='text'
            name='leagueLocation'
            value={formData.leagueLocation}
            onChange={handleChange}
            required
            minLength={3}
            pattern='^[a-zA-ZÀ-ÿ ]+$'
            title='Nome deve conter no mínimo 3 caracteres e apenas letras.'
            className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
          />
        </div>
        <div>
          <button
            type='submit'
            className='w-full flex justify-center py-2 px-4 border-2 border-transparent text-sm font-bold rounded-md text-neutral-800 bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-400 hover:border-neutral-300 active:border-neutral-400'
          >
            Registrar
          </button>
        </div>
      </form>
      <button
        onClick={handleBackToHome}
        className='mt-4 w-30 flex justify-center py-1 px-2 text-sm text-neutral-200 hover:text-neutral-300'
      >
        Voltar para a Página Principal
      </button>
    </div>
  );
};

export default AdminRegister;
