import React, { useState, useEffect } from 'react';
import api from '../../api';
import countryOptions from './features/nacionalidades';
import colorOptions from './features/cores';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserCreate = ({ onClose }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    nationality: '',
    age: '',
    userType: '',
    Photo: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [allowedUserTypes, setAllowedUserTypes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const calculateAllowedUserTypes = () => {
      if (userInfo.roles.includes('ROLE_SUPERADMIN')) {
        setAllowedUserTypes(['1', '2', '3', '4']);
      } else if (userInfo.roles.includes('ROLE_ADMIN')) {
        setAllowedUserTypes(['3', '4']);
      } else {
        setAllowedUserTypes([]);
      }
    };

    calculateAllowedUserTypes();
  }, [userInfo]);

  const handleOptionSelect = (value) => {
    setFormData({
      ...formData,
      nationality: value,
    });
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      await api.post('/user/create', formDataToSend);
      setFormSubmitted(true);
      setFormData({
        name: '',
        email: '',
        password: '',
        phone: '',
        nationality: '',
        age: '',
        userType: '',
        Photo: null,
      });
      toast.success('Usuário criado com sucesso!');
    } catch (error) {
      console.error('Erro ao criar usuário:', error);
      toast.error('Erro ao criar usuário');
    }
  };

  return (
    <div className='max-w-5xl mx-auto p-10 bg-neutral-800 rounded-2xl'>
      <div className='flex flex-row justify-between items-center'>
        <div>
          <h2 className='text-4xl font-bold mb-4 text-white'>Criar Usuário</h2>
        </div>
        <div className='hover:cursor-pointer' onClick={onClose}>
          <Icon icon='iconamoon:close' width='48' height='48' color='white' />
        </div>
      </div>
      <form onSubmit={handleSubmit} className='space-y-4'>
        <div>
          <label htmlFor='name' className='block font-semibold text-white'>
            Nome:
          </label>
          <input
            type='text'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
            minLength={3}
            pattern='^[a-zA-ZÀ-ÿ ]+$'
            title='Nome deve conter no mínimo 3 caracteres e apenas letras.'
          />
        </div>
        <div>
          <label htmlFor='email' className='block font-semibold text-white'>
            Email:
          </label>
          <input
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
            pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]+)?$'
            title='Insira um email válido (ex: exemplo@dominio.com)'
          />
        </div>
        <div>
          <label htmlFor='password' className='block font-semibold text-white'>
            Senha:
          </label>
          <input
            type='password'
            id='password'
            name='password'
            value={formData.password}
            onChange={handleChange}
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
            minLength={8}
            pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w!?._]{8,}$'
            title='Senha deve conter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula e um número.'
          />
        </div>
        <div>
          <label htmlFor='Photo' className='block font-semibold text-white'>
            Foto:
          </label>
          <input
            type='file'
            id='Photo'
            name='Photo'
            onChange={handleChange}
            className='mt-1 p-2 border bg-white border-neutral-300 rounded-md w-full'
            required
          />
        </div>
        <div>
          <label htmlFor='phone' className='block font-semibold text-white'>
            Telefone:
          </label>
          <input
            type='tel'
            id='phone'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            pattern='[0-9]{9}'
            title='Formato: 9 números'
            maxLength='9'
            minLength='9'
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
          />
        </div>
        <div>
          <label htmlFor='nationality' className='block font-semibold text-white'>
            Nacionalidade:
          </label>
          <div
            className={`relative mt-1 bg-white rounded-t-md w-full ${
              showDropdown ? '' : 'rounded-b-md'
            }`}
          >
            <div
              onClick={toggleDropdown}
              className='cursor-pointer appearance-none p-2 bg-transparent w-full flex justify-between items-center'
            >
              <span>{formData.nationality || 'Selecione a nacionalidade'}</span>
              <span>{showDropdown ? '▲' : '▼'}</span>
            </div>
            {showDropdown && (
              <div className='absolute top-full left-0 z-10 bg-white shadow-lg border border-gray-300 overflow-y-auto w-full h-64 rounded-b-md'>
                {countryOptions.map((option) => (
                  <div
                    key={option.key}
                    onClick={() => handleOptionSelect(option.value)}
                    className='p-2 hover:bg-gray-200 cursor-pointer'
                  >
                    {option.text}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <label htmlFor='colors' className='block font-semibold text-white'>
            Cores:
          </label>
          <select
            id='colors'
            name='colors'
            value={formData.colors}
            onChange={handleChange}
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
          >
            <option value='' disabled>
              Selecione a sua cor
            </option>
            {colorOptions.map((option) => (
              <option key={option.key} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor='age' className='block font-semibold text-white'>
            Idade:
          </label>
          <input
            type='number'
            id='age'
            name='age'
            value={formData.age}
            onChange={handleChange}
            min='16'
            max='90'
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
          />
        </div>
        <div>
          <label htmlFor='userType' className='block font-semibold text-white'>
            Tipo de Usuário:
          </label>
          <select
            id='userType'
            name='userType'
            value={formData.userType}
            onChange={handleChange}
            className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
            required
          >
            <option value='' disabled>
              Selecione o tipo
            </option>
            {allowedUserTypes.includes('1') && <option value='1'>SuperAdmin</option>}
            {allowedUserTypes.includes('2') && <option value='2'>Admin</option>}
            {allowedUserTypes.includes('3') && <option value='3'>Captain</option>}
            {allowedUserTypes.includes('4') && <option value='4'>Player</option>}
          </select>
        </div>
        <div>
          <button
            type='submit'
            className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'
            disabled={!allowedUserTypes.includes(formData.userType)}
          >
            Criar Usuário
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserCreate;
