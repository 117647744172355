// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebar: {
    open: false,
  },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeSidebar: (state, action) => {
      state.sidebar = {
        open: action.payload,
      };
    },
  },
});

export const { changeSidebar } = layoutSlice.actions;

export default layoutSlice.reducer;
