import React, { useState, useEffect } from 'react';
import { LineChart, ResponsiveContainer, Line, Tooltip } from 'recharts';
import api from '../../api';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';

const ChartBox = (props) => {
  const { color, icon, title, dataKey, linkPath, number, percentage, chartData } = props;

  return (
    <div className='flex h-full'>
      <div className='flex flex-1 flex-col justify-between'>
        <div className='flex items-center gap-3'>
          <Icon icon={icon} width='32' height='32' color='white' />
          <span>{title}</span>
        </div>
        <h1>{number}</h1>
        <Link className='' style={{ color: color }} to={linkPath}>
          Ver tudo
        </Link>
      </div>
      <div className='flex flex-col justify-between h-44 w-40'>
        <div className='h-full w-full'>
          <ResponsiveContainer width='99%' height='100%'>
            <LineChart data={chartData}>
              <Tooltip
                contentStyle={{ background: 'transparent', border: 'none' }}
                labelStyle={{ display: 'none' }}
                position={{ x: -80, y: 90 }}
              />
              <Line type='monotone' dataKey={dataKey} stroke={color} strokeWidth={2} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className='flex flex-col text-right'>
          <span className='font-bold text-xl' style={{ color: percentage < 0 ? 'tomato' : 'cyan' }}>
            {percentage}%
          </span>
          <span className=''>esta semana</span>
        </div>
      </div>
    </div>
  );
};

export default ChartBox;
