import React from 'react';
import { Outlet, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../app/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../Login';
import UserCreate from '../User/UserCreate';
import RegisterForm from '../Register';
import Dashboard from '../dashboard/Dashboard';
import Sidebar from '../SideBar';
import LeagueCreate from '../League/LeagueCreate';
import GameCreate from '../Games/GameCreate';
import Menu from '../Menu';
import Logs from '../Logs/Logs';
import User from '../User/User';
import League from '../League/League';
import Game from '../Games/Game';
import PlayerPage from '../Definitions/Definições';
import AdminRegister from '../AdminRegister';
import Enable from '../Enable';
import UserProfile from '../User/UserProfile';
import LeagueProfile from '../League/LeagueProfile';
import GameProfile from '../Games/GameProfile';
import UserActivation from '../UserActivaton/UserActivation';

function App() {
  const Layout = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/';
    const isAdminRegisterPage = location.pathname === '/adminregister';
    const isEnablePage = location.pathname === '/adminregister/accept';

    return (
      <div className='min-h-screen bg-neutral-900 flex flex-col'>
        <ToastContainer position='top-right' />
        {!isLoginPage && !isAdminRegisterPage && !isEnablePage && <Menu />}
        <div className='flex flex-grow'>
          {!isLoginPage && !isAdminRegisterPage && !isEnablePage && (
            <div className='bg-neutral-900 border-r-2 border-neutral-800'>
              <Sidebar />
            </div>
          )}
          <div className='bg-neutral-800 flex-grow'>
            <Outlet />
          </div>
        </div>
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Login />,
        },
        {
          path: '/adminregister',
          element: <AdminRegister />,
        },
        {
          path: '/register',
          element: <RegisterForm />,
        },
        {
          path: '/logs/list',
          element: <Logs />,
        },
        {
          path: '/dashboard',
          element: <Dashboard />,
        },
        {
          path: '/users/list',
          element: <User />,
        },
        {
          path: '/users/enable',
          element: <UserActivation />,
        },
        {
          path: '/users/create',
          element: <UserCreate />,
        },
        {
          path: '/league/list',
          element: <League />,
        },
        {
          path: '/league/create',
          element: <LeagueCreate />,
        },
        {
          path: '/games/list',
          element: <Game />,
        },
        {
          path: '/games/create',
          element: <GameCreate />,
        },
        {
          path: '/definições',
          element: <PlayerPage />,
        },
        {
          path: '/adminregister/accept',
          element: <Enable />,
        },
        {
          path: '/user/:id',
          element: <UserProfile />,
        },
        {
          path: '/league/:id',
          element: <LeagueProfile />,
        },
        {
          path: '/game/:id',
          element: <GameProfile />,
        },
      ],
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={router}>
        <ToastContainer />
        <Outlet />
      </RouterProvider>
    </Provider>
  );
}

export default App;
