import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../api';
import LeagueTable from '../LeagueTable';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

const UserProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [user, setUser] = useState(null);
  const [selectedLeagueId, setSelectedLeagueId] = useState(null);
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUserEnable, setUserEnable] = useState(null);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1286px)' });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await api.get(`/user/details/${id}`);
        setUser(response.data);
        setUserEnable(response.data.Enable);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [id]);

  const toggleEnable = async () => {
    setLoading(true);
    try {
      const response = await api.post(`/user/isEnable/${id}`, { Enable: !user.Enable });
      setUser((prevUser) => ({
        ...prevUser,
        Enable: response.data.Enable,
      }));
      toast.success('Status de ativação alterado com sucesso!');
      setUserEnable(!user.Enable);
    } catch (error) {
      console.error('Error updating user enable status:', error);
      toast.error('Erro ao alterar status de ativação');
    } finally {
      setLoading(false);
    }
  };

  const handleLeagueClick = (leagueId) => {
    setSelectedLeagueId(leagueId);
    const selectedLeague = user.leagues.find((league) => league.id === leagueId);
    setLeague(selectedLeague);
  };

  if (!user) {
    return <div className='flex items-center justify-center h-screen text-white'>Loading...</div>;
  }

  // Verificação de superadmin usando state.auth.userInfo
  const isSuperAdmin = userInfo.roles && userInfo.roles.includes('ROLE_SUPERADMIN');

  return (
    <>

      {isSmallScreen && (
        <>


          {/* Renderização do perfil do usuário */}
          <div className='w-full h-full grid grid-cols-1 auto-rows-[400px] gap-5 mx-auto p-4'>
            <div className='relative col-span-1 h-full overflow-auto border border-neutral-700 rounded-xl px-8 py-6 mb-8'>
              <div className='flex mb-4 items-center justify-between'>
                <h1 className='text-3xl font-bold text-white'>{user.name}</h1>
              </div>
              <div className='absolute top-5 right-5'>
                <img
                  src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                  alt='User'
                  className='w-28 h-28 rounded-full object-cover'
                />
              </div>
              <div className='grid grid-cols-1 gap-6'>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Id:</span> {user.id}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Email:</span> {user.email}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Telemóvel:</span> {user.Phone}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Tipo:</span> {user.type}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>País:</span> {user.Nacionality}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Idade:</span> {user.Age}
                  </p>
                </div>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vitórias:</span> {user.W}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Derrotas:</span> {user.L}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Desistências:</span> {user.F}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Jogos:</span> {user.Jogos}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Pontos Totais:</span> {user.Points}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Taxa de Vitórias:</span> {user.winRatioGeral}
                  </p>
                </div>
              </div>

              <div className='flex absolute bottom-2 right-4'>
                {/* Botão de ativação/desativação do usuário */}
                {isSuperAdmin && (
                  <button
                    onClick={toggleEnable}
                    className={`p-3 rounded-lg mb-2 w-auto text-white font-bold ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
                      }`}
                    disabled={loading}
                  >
                    {loading ? 'Aguarde...' : user.Enable ? 'Desativar Usuário' : 'Ativar Usuário'}
                  </button>
                )}
              </div>
            </div>
            {/* <input
          className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type='checkbox'
          value={isUserEnable}
          role='switch'
          id='flexSwitchCheckDefault'
          onChange={toggleEnable}
        />*/}

            <div className='col-span-1 border border-neutral-700 rounded-xl px-8 py-5'>
              <h2 className='text-lg text-white font-semibold mb-4'>Ligas Inscrito</h2>
              <ul className='flex flex-col overflow-auto divide-gray-200' style={{ height: '330px' }}>
                {user.leagues.map((league) => (
                  <li key={league.id} className='py-4 flex items-center justify-between'>
                    <div className='flex flex-col'>
                      <p className='text-lg font-semibold text-white'>{league.Name}</p>
                      <p className='text-sm text-white mb-2'>Início: {league.start_date}</p>
                      <p className='text-sm text-white mb-2'>Fim: {league.end_date}</p>
                    </div>
                    <div className='flex items-center'>
                      <img
                        src={`https://api.ligas.purplews.live/photos/${league.Image}`}
                        alt={league.Name}
                        className='w-28 h-28 rounded-full object-cover mr-4'
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className='col-span-1 row-span-2 border border-neutral-700 rounded-xl overflow-auto'>
              <div className='flex flex-col flex-wrap gap-2 p-5'>
                <div className='flex flex-row text-white text-2xl font-bold'>Ligas</div>
                <div className='flex flex-row gap-2'>
                  {user.leagues &&
                    user.leagues.map((league) => (
                      <div
                        key={league.id}
                        onClick={() => handleLeagueClick(league.id)}
                        className={`px-4 py-2 rounded cursor-pointer ${league.id === selectedLeagueId
                          ? 'bg-neutral-500 text-white'
                          : 'bg-neutral-700 text-white'
                          }`}
                      >
                        {league.Name}
                      </div>
                    ))}
                </div>
              </div>
              {selectedLeagueId && <LeagueTable selectedLeague={league} />}
            </div>
          </div>

        </>
      )}

      {!isSmallScreen && (
        <>


          {/* Renderização do perfil do usuário */}
          <div className='w-full h-full grid grid-cols-5 auto-rows-[400px] gap-5 mx-auto p-4'>
            <div className='flex flex-col relative col-span-2 h-full overflow-auto border border-neutral-700 rounded-xl px-8 py-6 mb-8'>
              <div className='flex mb-4 items-center justify-between'>
                <h1 className='text-3xl font-bold text-white'>{user.name}</h1>
              </div>
              <div className='absolute top-5 right-5'>
                <img
                  src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                  alt='User'
                  className='w-28 h-28 rounded-full object-cover'
                />
              </div>
              <div className='grid grid-cols-1 gap-6'>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Id:</span> {user.id}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Email:</span> {user.email}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Telemóvel:</span> {user.Phone}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Tipo:</span> {user.type}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>País:</span> {user.Nacionality}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Idade:</span> {user.Age}
                  </p>
                </div>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vitórias:</span> {user.W}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Derrotas:</span> {user.L}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Desistências:</span> {user.F}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Jogos:</span> {user.Jogos}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Pontos Totais:</span> {user.Points}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Taxa de Vitórias:</span> {user.winRatioGeral}
                  </p>
                </div>
              </div>

              <div className='flex absolute bottom-2 right-4'>
                {/* Botão de ativação/desativação do usuário */}
                {isSuperAdmin && (
                  <button
                    onClick={toggleEnable}
                    className={`p-3 rounded-lg mb-2 w-auto text-white font-bold ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
                      }`}
                    disabled={loading}
                  >
                    {loading ? 'Aguarde...' : user.Enable ? 'Desativar Usuário' : 'Ativar Usuário'}
                  </button>
                )}
              </div>
            </div>
            {/* <input
          className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type='checkbox'
          value={isUserEnable}
          role='switch'
          id='flexSwitchCheckDefault'
          onChange={toggleEnable}
        />*/}

            <div className='col-span-3 row-span-2 border border-neutral-700 rounded-xl overflow-auto'>
              <div className='flex flex-col flex-wrap gap-2 p-5'>
                <div className='flex flex-row text-white text-2xl font-bold'>Ligas</div>
                <div className='flex flex-row gap-2'>
                  {user.leagues &&
                    user.leagues.map((league) => (
                      <div
                        key={league.id}
                        onClick={() => handleLeagueClick(league.id)}
                        className={`px-4 py-2 rounded cursor-pointer ${league.id === selectedLeagueId
                          ? 'bg-neutral-500 text-white'
                          : 'bg-neutral-700 text-white'
                          }`}
                      >
                        {league.Name}
                      </div>
                    ))}
                </div>
              </div>
              {selectedLeagueId && <LeagueTable selectedLeague={league} />}
            </div>

            <div className='col-span-2 border border-neutral-700 rounded-xl px-8 py-5'>
              <h2 className='text-lg text-white font-semibold mb-4'>Ligas Inscrito</h2>
              <ul className='flex flex-col overflow-auto divide-gray-200' style={{ height: '330px' }}>
                {user.leagues.map((league) => (
                  <li key={league.id} className='py-4 flex items-center justify-between'>
                    <div className='flex flex-col'>
                      <p className='text-lg font-semibold text-white'>{league.Name}</p>
                      <p className='text-sm text-white mb-2'>Início: {league.start_date}</p>
                      <p className='text-sm text-white mb-2'>Fim: {league.end_date}</p>
                    </div>
                    <div className='flex items-center'>
                      <img
                        src={`https://api.ligas.purplews.live/photos/${league.Image}`}
                        alt={league.Name}
                        className='w-28 h-28 rounded-full object-cover mr-4'
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </>
      )}
    </>
  );
};

export default UserProfile;
