const WelcomeBox = () => {
  return (
    <div className='flex h-full relative'>
      <div className='text-white absolute bottom-16 left-16 font-bold text-3xl'>
        <h1>Bem-vindo</h1>
      </div>
    </div>
  );
};

export default WelcomeBox;
