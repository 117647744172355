import React, { useEffect, useState } from 'react';
import DataTable from '../dataTable/dataTable';
import api from '../../api';
import { enableUserList } from '../data/DataComponent';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import UserEdit from '../User/UserEdit';

const UserActivation = () => {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState(false);
    const [editUserId, setEditUserId] = useState(null);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const authInfo = useSelector((state) => state.auth);
    const userInfo = authInfo.userInfo;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/user/isEnable');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const newUserPopUp = () => {
        setNewUser(true);
    };

    const closeUserPopUp = () => {
        setNewUser(false);
    };

    const confirmDeleteUser = (userId) => {
        setUserIdToDelete(userId);
        setShowDeleteModal(true);
    };

    const deleteUser = async () => {
        try {
            await api.delete(`/user/delete/${userIdToDelete}`);
            setUsers(users.filter((user) => user.id !== userIdToDelete));
            setShowDeleteModal(false);
            toast.success('Usuário excluído com sucesso!');
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Erro ao excluir usuário');
        }
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
        setUserIdToDelete(null);
    };

    const editUser = (userId) => {
        setEditUserId(userId);
    };

    const closeEditUser = () => {
        setEditUserId(null);
    };

    const handleSave = () => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/user/isEnable');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    };

    const onViewUser = (userId) => {
        navigate(`/user/${userId}`);
    };

    // Add a check to ensure userInfo and users are defined
    if (!userInfo || !users) {
        return null;
    }

    const userData = enableUserList(
        users.filter((user) => {
            const isSameLeague = userInfo.leagues?.some((league) =>
                user.leagues?.map((l) => l.id).includes(league.id)
            );

            if (userInfo.type === 'admin') {
                return (user.type === 'captain' || user.type === 'player') && isSameLeague;
            } else {
                return true;
            }
        })
    );

    return (
        <div className='flex flex-col w-full h-full p-10 bg-neutral-800'>
            {editUserId && <UserEdit userId={editUserId} onClose={closeEditUser} onSave={handleSave} />}

            {showDeleteModal && (
                <div className='absolute bg-black bg-opacity-75 inset-0 flex items-center justify-center z-40'>
                    <div className='bg-neutral-800 z-50 p-5 rounded-lg w-96'>
                        <h2 className='text-white mb-4'>Tem a certeza que deseja apagar este usuário?</h2>
                        <div className='flex justify-center'>
                            <button
                                onClick={deleteUser}
                                className='p-2 mr-5 rounded bg-red-500 hover:bg-red-600 active:bg-red-700 text-white'
                            >
                                Confirmar
                            </button>
                            <button
                                onClick={cancelDelete}
                                className='p-2 ml-5 rounded bg-gray-500 hover:bg-gray-600 active:bg-gray-700 text-white'
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='flex flex-row text-white justify-between items-center mb-5'>
                <div className='flex flex-row gap-3 items-center'>
                    {userData?.icon && <Icon icon={userData.icon} width='32' height='32' color='white' />}
                    <span className='text-xl font-bold'>{userData?.title}</span>
                </div>
                <div>
                    <button
                        onClick={newUserPopUp}
                        className='p-3 rounded-lg w-auto bg-blue-600 hover:bg-blue-700 active:bg-blue-800 text-white'
                    >
                        Novo Usuário
                    </button>
                </div>
            </div>
            {userData && (
                <DataTable
                    tableHeads={userData.tableHeads}
                    tableRows={userData.tableData}
                    onDelete={confirmDeleteUser}
                    onEdit={editUser}
                    onView={onViewUser}
                />
            )}
        </div>
    );
};

export default UserActivation;
