import { Flag } from 'semantic-ui-react';

const countryOptions = [
  {
    key: 'pt',
    value: 'Portugal',
    text: (
      <>
        <Flag name='portugal' />
        Portugal
      </>
    ),
  },
  {
    key: 'af',
    value: 'Afghanistan',
    text: (
      <>
        <Flag name='afghanistan' />
        Afghanistan
      </>
    ),
  },
  {
    key: 'ax',
    value: 'Aland Islands',
    text: (
      <>
        <Flag name='aland islands' />
        Aland Islands
      </>
    ),
  },
  {
    key: 'al',
    value: 'Albania',
    text: (
      <>
        <Flag name='albania' />
        Albania
      </>
    ),
  },
  {
    key: 'dz',
    value: 'Algeria',
    text: (
      <>
        <Flag name='algeria' />
        Algeria
      </>
    ),
  },
  {
    key: 'as',
    value: 'American Samoa',
    text: (
      <>
        <Flag name='american samoa' />
        American Samoa
      </>
    ),
  },
  {
    key: 'ad',
    value: 'Andorra',
    text: (
      <>
        <Flag name='andorra' />
        Andorra
      </>
    ),
  },
  {
    key: 'ao',
    value: 'Angola',
    text: (
      <>
        <Flag name='angola' />
        Angola
      </>
    ),
  },
  {
    key: 'ai',
    value: 'Anguilla',
    text: (
      <>
        <Flag name='anguilla' />
        Anguilla
      </>
    ),
  },
  {
    key: 'ag',
    value: 'Antigua',
    text: (
      <>
        <Flag name='antigua' />
        Antigua
      </>
    ),
  },
  {
    key: 'ar',
    value: 'Argentina',
    text: (
      <>
        <Flag name='argentina' />
        Argentina
      </>
    ),
  },
  {
    key: 'am',
    value: 'Armenia',
    text: (
      <>
        <Flag name='armenia' />
        Armenia
      </>
    ),
  },
  {
    key: 'aw',
    value: 'Aruba',
    text: (
      <>
        <Flag name='aruba' />
        Aruba
      </>
    ),
  },
  {
    key: 'au',
    value: 'Australia',
    text: (
      <>
        <Flag name='australia' />
        Australia
      </>
    ),
  },
  {
    key: 'at',
    value: 'Austria',
    text: (
      <>
        <Flag name='austria' />
        Austria
      </>
    ),
  },
  {
    key: 'az',
    value: 'Azerbaijan',
    text: (
      <>
        <Flag name='azerbaijan' />
        Azerbaijan
      </>
    ),
  },
  {
    key: 'bs',
    value: 'Bahamas',
    text: (
      <>
        <Flag name='bahamas' />
        Bahamas
      </>
    ),
  },
  {
    key: 'bh',
    value: 'Bahrain',
    text: (
      <>
        <Flag name='bahrain' />
        Bahrain
      </>
    ),
  },
  {
    key: 'bd',
    value: 'Bangladesh',
    text: (
      <>
        <Flag name='bangladesh' />
        Bangladesh
      </>
    ),
  },
  {
    key: 'bb',
    value: 'Barbados',
    text: (
      <>
        <Flag name='barbados' />
        Barbados
      </>
    ),
  },
  {
    key: 'by',
    value: 'Belarus',
    text: (
      <>
        <Flag name='belarus' />
        Belarus
      </>
    ),
  },
  {
    key: 'be',
    value: 'Belgium',
    text: (
      <>
        <Flag name='belgium' />
        Belgium
      </>
    ),
  },
  {
    key: 'bz',
    value: 'Belize',
    text: (
      <>
        <Flag name='belize' />
        Belize
      </>
    ),
  },
  {
    key: 'bj',
    value: 'Benin',
    text: (
      <>
        <Flag name='benin' />
        Benin
      </>
    ),
  },
  {
    key: 'bm',
    value: 'Bermuda',
    text: (
      <>
        <Flag name='bermuda' />
        Bermuda
      </>
    ),
  },
  {
    key: 'bt',
    value: 'Bhutan',
    text: (
      <>
        <Flag name='bhutan' />
        Bhutan
      </>
    ),
  },
  {
    key: 'bo',
    value: 'Bolivia',
    text: (
      <>
        <Flag name='bolivia' />
        Bolivia
      </>
    ),
  },
  {
    key: 'ba',
    value: 'Bosnia',
    text: (
      <>
        <Flag name='bosnia' />
        Bosnia
      </>
    ),
  },
  {
    key: 'bw',
    value: 'Botswana',
    text: (
      <>
        <Flag name='botswana' />
        Botswana
      </>
    ),
  },
  {
    key: 'bv',
    value: 'Bouvet Island',
    text: (
      <>
        <Flag name='bouvet island' />
        Bouvet Island
      </>
    ),
  },
  {
    key: 'br',
    value: 'Brazil',
    text: (
      <>
        <Flag name='brazil' />
        Brazil
      </>
    ),
  },
  {
    key: 'vg',
    value: 'British Virgin Islands',
    text: (
      <>
        <Flag name='british virgin islands' />
        British Virgin Islands
      </>
    ),
  },
  {
    key: 'bn',
    value: 'Brunei',
    text: (
      <>
        <Flag name='brunei' />
        Brunei
      </>
    ),
  },
  {
    key: 'bg',
    value: 'Bulgaria',
    text: (
      <>
        <Flag name='bulgaria' />
        Bulgaria
      </>
    ),
  },
  {
    key: 'bf',
    value: 'Burkina Faso',
    text: (
      <>
        <Flag name='burkina faso' />
        Burkina Faso
      </>
    ),
  },
  {
    key: 'mm',
    value: 'Burma (Myanmar)',
    text: (
      <>
        <Flag name='burma (myanmar)' />
        Burma (Myanmar)
      </>
    ),
  },
  {
    key: 'bi',
    value: 'Burundi',
    text: (
      <>
        <Flag name='burundi' />
        Burundi
      </>
    ),
  },
  {
    key: 'tc',
    value: 'Caicos Islands',
    text: (
      <>
        <Flag name='caicos islands' />
        Caicos Islands
      </>
    ),
  },
  {
    key: 'kh',
    value: 'Cambodia',
    text: (
      <>
        <Flag name='cambodia' />
        Cambodia
      </>
    ),
  },
  {
    key: 'cm',
    value: 'Cameroon',
    text: (
      <>
        <Flag name='cameroon' />
        Cameroon
      </>
    ),
  },
  {
    key: 'ca',
    value: 'Canada',
    text: (
      <>
        <Flag name='canada' />
        Canada
      </>
    ),
  },
  {
    key: 'cv',
    value: 'Cape Verde',
    text: (
      <>
        <Flag name='cape verde' />
        Cape Verde
      </>
    ),
  },
  {
    key: 'ky',
    value: 'Cayman Islands',
    text: (
      <>
        <Flag name='cayman islands' />
        Cayman Islands
      </>
    ),
  },
  {
    key: 'cf',
    value: 'Central African Republic',
    text: (
      <>
        <Flag name='central african republic' />
        Central African Republic
      </>
    ),
  },
  {
    key: 'td',
    value: 'Chad',
    text: (
      <>
        <Flag name='chad' />
        Chad
      </>
    ),
  },
  {
    key: 'cl',
    value: 'Chile',
    text: (
      <>
        <Flag name='chile' />
        Chile
      </>
    ),
  },
  {
    key: 'cn',
    value: 'China',
    text: (
      <>
        <Flag name='china' />
        China
      </>
    ),
  },
  {
    key: 'cx',
    value: 'Christmas Island',
    text: (
      <>
        <Flag name='christmas island' />
        Christmas Island
      </>
    ),
  },
  {
    key: 'cc',
    value: 'Cocos Islands',
    text: (
      <>
        <Flag name='cocos islands' />
        Cocos Islands
      </>
    ),
  },
  {
    key: 'co',
    value: 'Colombia',
    text: (
      <>
        <Flag name='colombia' />
        Colombia
      </>
    ),
  },
  {
    key: 'km',
    value: 'Comoros',
    text: (
      <>
        <Flag name='comoros' />
        Comoros
      </>
    ),
  },
  {
    key: 'cd',
    value: 'Congo',
    text: (
      <>
        <Flag name='congo' />
        Congo
      </>
    ),
  },
  {
    key: 'cg',
    value: 'Congo Brazzaville',
    text: (
      <>
        <Flag name='congo brazzaville' />
        Congo Brazzaville
      </>
    ),
  },
  {
    key: 'ck',
    value: 'Cook Islands',
    text: (
      <>
        <Flag name='cook islands' />
        Cook Islands
      </>
    ),
  },
  {
    key: 'cr',
    value: 'Costa Rica',
    text: (
      <>
        <Flag name='costa rica' />
        Costa Rica
      </>
    ),
  },
  {
    key: 'ci',
    value: 'Cote Divoire',
    text: (
      <>
        <Flag name='cote divoire' />
        Cote Divoire
      </>
    ),
  },
  {
    key: 'hr',
    value: 'Croatia',
    text: (
      <>
        <Flag name='croatia' />
        Croatia
      </>
    ),
  },
  {
    key: 'cu',
    value: 'Cuba',
    text: (
      <>
        <Flag name='cuba' />
        Cuba
      </>
    ),
  },
  {
    key: 'cy',
    value: 'Cyprus',
    text: (
      <>
        <Flag name='cyprus' />
        Cyprus
      </>
    ),
  },
  {
    key: 'cz',
    value: 'Czech Republic',
    text: (
      <>
        <Flag name='czech republic' />
        Czech Republic
      </>
    ),
  },
  {
    key: 'dk',
    value: 'Denmark',
    text: (
      <>
        <Flag name='denmark' />
        Denmark
      </>
    ),
  },
  {
    key: 'dj',
    value: 'Djibouti',
    text: (
      <>
        <Flag name='djibouti' />
        Djibouti
      </>
    ),
  },
  {
    key: 'dm',
    value: 'Dominica',
    text: (
      <>
        <Flag name='dominica' />
        Dominica
      </>
    ),
  },
  {
    key: 'do',
    value: 'Dominican Republic',
    text: (
      <>
        <Flag name='dominican republic' />
        Dominican Republic
      </>
    ),
  },
  {
    key: 'ec',
    value: 'Ecuador',
    text: (
      <>
        <Flag name='ecuador' />
        Ecuador
      </>
    ),
  },
  {
    key: 'eg',
    value: 'Egypt',
    text: (
      <>
        <Flag name='egypt' />
        Egypt
      </>
    ),
  },
  {
    key: 'sv',
    value: 'El Salvador',
    text: (
      <>
        <Flag name='el salvador' />
        El Salvador
      </>
    ),
  },
  {
    key: 'gb eng',
    value: 'England',
    text: (
      <>
        <Flag name='england' />
        England
      </>
    ),
  },
  {
    key: 'gq',
    value: 'Equatorial Guinea',
    text: (
      <>
        <Flag name='equatorial guinea' />
        Equatorial Guinea
      </>
    ),
  },
  {
    key: 'er',
    value: 'Eritrea',
    text: (
      <>
        <Flag name='eritrea' />
        Eritrea
      </>
    ),
  },
  {
    key: 'ee',
    value: 'Estonia',
    text: (
      <>
        <Flag name='estonia' />
        Estonia
      </>
    ),
  },
  {
    key: 'et',
    value: 'Ethiopia',
    text: (
      <>
        <Flag name='ethiopia' />
        Ethiopia
      </>
    ),
  },
  {
    key: 'fk',
    value: 'Falkland Islands',
    text: (
      <>
        <Flag name='falkland islands' />
        Falkland Islands
      </>
    ),
  },
  {
    key: 'fo',
    value: 'Faroe Islands',
    text: (
      <>
        <Flag name='faroe islands' />
        Faroe Islands
      </>
    ),
  },
  {
    key: 'fj',
    value: 'Fiji',
    text: (
      <>
        <Flag name='fiji' />
        Fiji
      </>
    ),
  },
  {
    key: 'fi',
    value: 'Finland',
    text: (
      <>
        <Flag name='finland' />
        Finland
      </>
    ),
  },
  {
    key: 'fr',
    value: 'France',
    text: (
      <>
        <Flag name='france' />
        France
      </>
    ),
  },
  {
    key: 'gf',
    value: 'French Guiana',
    text: (
      <>
        <Flag name='french guiana' />
        French Guiana
      </>
    ),
  },
  {
    key: 'pf',
    value: 'French Polynesia',
    text: (
      <>
        <Flag name='french polynesia' />
        French Polynesia
      </>
    ),
  },
  {
    key: 'tf',
    value: 'French Southern Territories',
    text: (
      <>
        <Flag name='french southern territories' />
        French Southern Territories
      </>
    ),
  },
  {
    key: 'ga',
    value: 'Gabon',
    text: (
      <>
        <Flag name='gabon' />
        Gabon
      </>
    ),
  },
  {
    key: 'gm',
    value: 'Gambia',
    text: (
      <>
        <Flag name='gambia' />
        Gambia
      </>
    ),
  },
  {
    key: 'ge',
    value: 'Georgia',
    text: (
      <>
        <Flag name='georgia' />
        Georgia
      </>
    ),
  },
  {
    key: 'de',
    value: 'Germany',
    text: (
      <>
        <Flag name='germany' />
        Germany
      </>
    ),
  },
  {
    key: 'gh',
    value: 'Ghana',
    text: (
      <>
        <Flag name='ghana' />
        Ghana
      </>
    ),
  },
  {
    key: 'gi',
    value: 'Gibraltar',
    text: (
      <>
        <Flag name='gibraltar' />
        Gibraltar
      </>
    ),
  },
  {
    key: 'gr',
    value: 'Greece',
    text: (
      <>
        <Flag name='greece' />
        Greece
      </>
    ),
  },
  {
    key: 'gl',
    value: 'Greenland',
    text: (
      <>
        <Flag name='greenland' />
        Greenland
      </>
    ),
  },
  {
    key: 'gd',
    value: 'Grenada',
    text: (
      <>
        <Flag name='grenada' />
        Grenada
      </>
    ),
  },
  {
    key: 'gp',
    value: 'Guadeloupe',
    text: (
      <>
        <Flag name='guadeloupe' />
        Guadeloupe
      </>
    ),
  },
  {
    key: 'gu',
    value: 'Guam',
    text: (
      <>
        <Flag name='guam' />
        Guam
      </>
    ),
  },
  {
    key: 'gt',
    value: 'Guatemala',
    text: (
      <>
        <Flag name='guatemala' />
        Guatemala
      </>
    ),
  },
  {
    key: 'gn',
    value: 'Guinea',
    text: (
      <>
        <Flag name='guinea' />
        Guinea
      </>
    ),
  },
  {
    key: 'gw',
    value: 'Guinea-Bissau',
    text: (
      <>
        <Flag name='guinea-bissau' />
        Guinea-Bissau
      </>
    ),
  },
  {
    key: 'gy',
    value: 'Guyana',
    text: (
      <>
        <Flag name='guyana' />
        Guyana
      </>
    ),
  },
  {
    key: 'ht',
    value: 'Haiti',
    text: (
      <>
        <Flag name='haiti' />
        Haiti
      </>
    ),
  },
  {
    key: 'hm',
    value: 'Heard Island and Mcdonald Islands',
    text: (
      <>
        <Flag name='heard island and mcdonald islands' />
        Heard Island and Mcdonald Islands
      </>
    ),
  },
  {
    key: 'hn',
    value: 'Honduras',
    text: (
      <>
        <Flag name='honduras' />
        Honduras
      </>
    ),
  },
  {
    key: 'hk',
    value: 'Hong Kong',
    text: (
      <>
        <Flag name='hong kong' />
        Hong Kong
      </>
    ),
  },
  {
    key: 'hu',
    value: 'Hungary',
    text: (
      <>
        <Flag name='hungary' />
        Hungary
      </>
    ),
  },
  {
    key: 'is',
    value: 'Iceland',
    text: (
      <>
        <Flag name='iceland' />
        Iceland
      </>
    ),
  },
  {
    key: 'in',
    value: 'India',
    text: (
      <>
        <Flag name='india' />
        India
      </>
    ),
  },
  {
    key: 'io',
    value: 'Indian Ocean Territory',
    text: (
      <>
        <Flag name='indian ocean territory' />
        Indian Ocean Territory
      </>
    ),
  },
  {
    key: 'id',
    value: 'Indonesia',
    text: (
      <>
        <Flag name='indonesia' />
        Indonesia
      </>
    ),
  },
  {
    key: 'ir',
    value: 'Iran',
    text: (
      <>
        <Flag name='iran' />
        Iran
      </>
    ),
  },
  {
    key: 'iq',
    value: 'Iraq',
    text: (
      <>
        <Flag name='iraq' />
        Iraq
      </>
    ),
  },
  {
    key: 'ie',
    value: 'Ireland',
    text: (
      <>
        <Flag name='ireland' />
        Ireland
      </>
    ),
  },
  {
    key: 'il',
    value: 'Israel',
    text: (
      <>
        <Flag name='israel' />
        Israel
      </>
    ),
  },
  {
    key: 'it',
    value: 'Italy',
    text: (
      <>
        <Flag name='italy' />
        Italy
      </>
    ),
  },
  {
    key: 'jm',
    value: 'Jamaica',
    text: (
      <>
        <Flag name='jamaica' />
        Jamaica
      </>
    ),
  },
  {
    key: 'jp',
    value: 'Japan',
    text: (
      <>
        <Flag name='japan' />
        Japan
      </>
    ),
  },
  {
    key: 'jo',
    value: 'Jordan',
    text: (
      <>
        <Flag name='jordan' />
        Jordan
      </>
    ),
  },
  {
    key: 'kz',
    value: 'Kazakhstan',
    text: (
      <>
        <Flag name='kazakhstan' />
        Kazakhstan
      </>
    ),
  },
  {
    key: 'ke',
    value: 'Kenya',
    text: (
      <>
        <Flag name='kenya' />
        Kenya
      </>
    ),
  },
  {
    key: 'ki',
    value: 'Kiribati',
    text: (
      <>
        <Flag name='kiribati' />
        Kiribati
      </>
    ),
  },
  {
    key: 'kw',
    value: 'Kuwait',
    text: (
      <>
        <Flag name='kuwait' />
        Kuwait
      </>
    ),
  },
  {
    key: 'kg',
    value: 'Kyrgyzstan',
    text: (
      <>
        <Flag name='kyrgyzstan' />
        Kyrgyzstan
      </>
    ),
  },
  {
    key: 'la',
    value: 'Laos',
    text: (
      <>
        <Flag name='laos' />
        Laos
      </>
    ),
  },
  {
    key: 'lv',
    value: 'Latvia',
    text: (
      <>
        <Flag name='latvia' />
        Latvia
      </>
    ),
  },
  {
    key: 'lb',
    value: 'Lebanon',
    text: (
      <>
        <Flag name='lebanon' />
        Lebanon
      </>
    ),
  },
  {
    key: 'ls',
    value: 'Lesotho',
    text: (
      <>
        <Flag name='lesotho' />
        Lesotho
      </>
    ),
  },
  {
    key: 'lr',
    value: 'Liberia',
    text: (
      <>
        <Flag name='liberia' />
        Liberia
      </>
    ),
  },
  {
    key: 'ly',
    value: 'Libya',
    text: (
      <>
        <Flag name='libya' />
        Libya
      </>
    ),
  },
  {
    key: 'li',
    value: 'Liechtenstein',
    text: (
      <>
        <Flag name='liechtenstein' />
        Liechtenstein
      </>
    ),
  },
  {
    key: 'lt',
    value: 'Lithuania',
    text: (
      <>
        <Flag name='lithuania' />
        Lithuania
      </>
    ),
  },
  {
    key: 'lu',
    value: 'Luxembourg',
    text: (
      <>
        <Flag name='luxembourg' />
        Luxembourg
      </>
    ),
  },
  {
    key: 'mk',
    value: 'Macedonia',
    text: (
      <>
        <Flag name='macedonia' />
        Macedonia
      </>
    ),
  },
  {
    key: 'mg',
    value: 'Madagascar',
    text: (
      <>
        <Flag name='madagascar' />
        Madagascar
      </>
    ),
  },
  {
    key: 'mw',
    value: 'Malawi',
    text: (
      <>
        <Flag name='malawi' />
        Malawi
      </>
    ),
  },
  {
    key: 'my',
    value: 'Malaysia',
    text: (
      <>
        <Flag name='malaysia' />
        Malaysia
      </>
    ),
  },
  {
    key: 'mv',
    value: 'Maldives',
    text: (
      <>
        <Flag name='maldives' />
        Maldives
      </>
    ),
  },
  {
    key: 'ml',
    value: 'Mali',
    text: (
      <>
        <Flag name='mali' />
        Mali
      </>
    ),
  },
  {
    key: 'mt',
    value: 'Malta',
    text: (
      <>
        <Flag name='malta' />
        Malta
      </>
    ),
  },
  {
    key: 'mh',
    value: 'Marshall Islands',
    text: (
      <>
        <Flag name='marshall islands' />
        Marshall Islands
      </>
    ),
  },
  {
    key: 'mq',
    value: 'Martinique',
    text: (
      <>
        <Flag name='martinique' />
        Martinique
      </>
    ),
  },
  {
    key: 'mr',
    value: 'Mauritania',
    text: (
      <>
        <Flag name='mauritania' />
        Mauritania
      </>
    ),
  },
  {
    key: 'mu',
    value: 'Mauritius',
    text: (
      <>
        <Flag name='mauritius' />
        Mauritius
      </>
    ),
  },
  {
    key: 'yt',
    value: 'Mayotte',
    text: (
      <>
        <Flag name='mayotte' />
        Mayotte
      </>
    ),
  },
  {
    key: 'mx',
    value: 'Mexico',
    text: (
      <>
        <Flag name='mexico' />
        Mexico
      </>
    ),
  },
  {
    key: 'fm',
    value: 'Micronesia',
    text: (
      <>
        <Flag name='micronesia' />
        Micronesia
      </>
    ),
  },
  {
    key: 'md',
    value: 'Moldova',
    text: (
      <>
        <Flag name='moldova' />
        Moldova
      </>
    ),
  },
  {
    key: 'mc',
    value: 'Monaco',
    text: (
      <>
        <Flag name='monaco' />
        Monaco
      </>
    ),
  },
  {
    key: 'mn',
    value: 'Mongolia',
    text: (
      <>
        <Flag name='mongolia' />
        Mongolia
      </>
    ),
  },
  {
    key: 'me',
    value: 'Montenegro',
    text: (
      <>
        <Flag name='montenegro' />
        Montenegro
      </>
    ),
  },
  {
    key: 'ms',
    value: 'Montserrat',
    text: (
      <>
        <Flag name='montserrat' />
        Montserrat
      </>
    ),
  },
  {
    key: 'ma',
    value: 'Morocco',
    text: (
      <>
        <Flag name='morocco' />
        Morocco
      </>
    ),
  },
  {
    key: 'mz',
    value: 'Mozambique',
    text: (
      <>
        <Flag name='mozambique' />
        Mozambique
      </>
    ),
  },
  {
    key: 'na',
    value: 'Namibia',
    text: (
      <>
        <Flag name='namibia' />
        Namibia
      </>
    ),
  },
  {
    key: 'nr',
    value: 'Nauru',
    text: (
      <>
        <Flag name='nauru' />
        Nauru
      </>
    ),
  },
  {
    key: 'np',
    value: 'Nepal',
    text: (
      <>
        <Flag name='nepal' />
        Nepal
      </>
    ),
  },
  {
    key: 'nl',
    value: 'Netherlands',
    text: (
      <>
        <Flag name='netherlands' />
        Netherlands
      </>
    ),
  },
  {
    key: 'an',
    value: 'Netherlands Antilles',
    text: (
      <>
        <Flag name='netherlands antilles' />
        Netherlands Antilles
      </>
    ),
  },
  {
    key: 'nc',
    value: 'New Caledonia',
    text: (
      <>
        <Flag name='new caledonia' />
        New Caledonia
      </>
    ),
  },
  {
    key: 'nz',
    value: 'New Zealand',
    text: (
      <>
        <Flag name='new zealand' />
        New Zealand
      </>
    ),
  },
  {
    key: 'ni',
    value: 'Nicaragua',
    text: (
      <>
        <Flag name='nicaragua' />
        Nicaragua
      </>
    ),
  },
  {
    key: 'ne',
    value: 'Niger',
    text: (
      <>
        <Flag name='niger' />
        Niger
      </>
    ),
  },
  {
    key: 'ng',
    value: 'Nigeria',
    text: (
      <>
        <Flag name='nigeria' />
        Nigeria
      </>
    ),
  },
  {
    key: 'nu',
    value: 'Niue',
    text: (
      <>
        <Flag name='niue' />
        Niue
      </>
    ),
  },
  {
    key: 'nf',
    value: 'Norfolk Island',
    text: (
      <>
        <Flag name='norfolk island' />
        Norfolk Island
      </>
    ),
  },
  {
    key: 'kp',
    value: 'North Korea',
    text: (
      <>
        <Flag name='north korea' />
        North Korea
      </>
    ),
  },
  {
    key: 'mp',
    value: 'Northern Mariana Islands',
    text: (
      <>
        <Flag name='northern mariana islands' />
        Northern Mariana Islands
      </>
    ),
  },
  {
    key: 'no',
    value: 'Norway',
    text: (
      <>
        <Flag name='norway' />
        Norway
      </>
    ),
  },
  {
    key: 'om',
    value: 'Oman',
    text: (
      <>
        <Flag name='oman' />
        Oman
      </>
    ),
  },
  {
    key: 'pk',
    value: 'Pakistan',
    text: (
      <>
        <Flag name='pakistan' />
        Pakistan
      </>
    ),
  },
  {
    key: 'pw',
    value: 'Palau',
    text: (
      <>
        <Flag name='palau' />
        Palau
      </>
    ),
  },
  {
    key: 'ps',
    value: 'Palestine',
    text: (
      <>
        <Flag name='palestine' />
        Palestine
      </>
    ),
  },
  {
    key: 'pa',
    value: 'Panama',
    text: (
      <>
        <Flag name='panama' />
        Panama
      </>
    ),
  },
  {
    key: 'pg',
    value: 'Papua New Guinea',
    text: (
      <>
        <Flag name='papua new guinea' />
        Papua New Guinea
      </>
    ),
  },
  {
    key: 'py',
    value: 'Paraguay',
    text: (
      <>
        <Flag name='paraguay' />
        Paraguay
      </>
    ),
  },
  {
    key: 'pe',
    value: 'Peru',
    text: (
      <>
        <Flag name='peru' />
        Peru
      </>
    ),
  },
  {
    key: 'ph',
    value: 'Philippines',
    text: (
      <>
        <Flag name='philippines' />
        Philippines
      </>
    ),
  },
  {
    key: 'pn',
    value: 'Pitcairn Islands',
    text: (
      <>
        <Flag name='pitcairn islands' />
        Pitcairn Islands
      </>
    ),
  },
  {
    key: 'pl',
    value: 'Poland',
    text: (
      <>
        <Flag name='poland' />
        Poland
      </>
    ),
  },
  {
    key: 'pr',
    value: 'Puerto Rico',
    text: (
      <>
        <Flag name='puerto rico' />
        Puerto Rico
      </>
    ),
  },
  {
    key: 'qa',
    value: 'Qatar',
    text: (
      <>
        <Flag name='qatar' />
        Qatar
      </>
    ),
  },
  {
    key: 're',
    value: 'Reunion',
    text: (
      <>
        <Flag name='reunion' />
        Reunion
      </>
    ),
  },
  {
    key: 'ro',
    value: 'Romania',
    text: (
      <>
        <Flag name='romania' />
        Romania
      </>
    ),
  },
  {
    key: 'ru',
    value: 'Russia',
    text: (
      <>
        <Flag name='russia' />
        Russia
      </>
    ),
  },
  {
    key: 'rw',
    value: 'Rwanda',
    text: (
      <>
        <Flag name='rwanda' />
        Rwanda
      </>
    ),
  },
  {
    key: 'sh',
    value: 'Saint Helena',
    text: (
      <>
        <Flag name='saint helena' />
        Saint Helena
      </>
    ),
  },
  {
    key: 'kn',
    value: 'Saint Kitts and Nevis',
    text: (
      <>
        <Flag name='saint kitts and nevis' />
        Saint Kitts and Nevis
      </>
    ),
  },
  {
    key: 'lc',
    value: 'Saint Lucia',
    text: (
      <>
        <Flag name='saint lucia' />
        Saint Lucia
      </>
    ),
  },
  {
    key: 'pm',
    value: 'Saint Pierre and Miquelon',
    text: (
      <>
        <Flag name='saint pierre and miquelon' />
        Saint Pierre and Miquelon
      </>
    ),
  },
  {
    key: 'vc',
    value: 'Saint Vincent and the Grenadines',
    text: (
      <>
        <Flag name='saint vincent and the grenadines' />
        Saint Vincent and the Grenadines
      </>
    ),
  },
  {
    key: 'ws',
    value: 'Samoa',
    text: (
      <>
        <Flag name='samoa' />
        Samoa
      </>
    ),
  },
  {
    key: 'sm',
    value: 'San Marino',
    text: (
      <>
        <Flag name='san marino' />
        San Marino
      </>
    ),
  },
  {
    key: 'st',
    value: 'Sao Tome and Principe',
    text: (
      <>
        <Flag name='sao tome and principe' />
        Sao Tome and Principe
      </>
    ),
  },
  {
    key: 'sa',
    value: 'Saudi Arabia',
    text: (
      <>
        <Flag name='saudi arabia' />
        Saudi Arabia
      </>
    ),
  },
  {
    key: 'sn',
    value: 'Senegal',
    text: (
      <>
        <Flag name='senegal' />
        Senegal
      </>
    ),
  },
  {
    key: 'rs',
    value: 'Serbia',
    text: (
      <>
        <Flag name='serbia' />
        Serbia
      </>
    ),
  },
  {
    key: 'sc',
    value: 'Seychelles',
    text: (
      <>
        <Flag name='seychelles' />
        Seychelles
      </>
    ),
  },
  {
    key: 'sl',
    value: 'Sierra Leone',
    text: (
      <>
        <Flag name='sierra leone' />
        Sierra Leone
      </>
    ),
  },
  {
    key: 'sg',
    value: 'Singapore',
    text: (
      <>
        <Flag name='singapore' />
        Singapore
      </>
    ),
  },
  {
    key: 'sk',
    value: 'Slovakia',
    text: (
      <>
        <Flag name='slovakia' />
        Slovakia
      </>
    ),
  },
  {
    key: 'si',
    value: 'Slovenia',
    text: (
      <>
        <Flag name='slovenia' />
        Slovenia
      </>
    ),
  },
  {
    key: 'sb',
    value: 'Solomon Islands',
    text: (
      <>
        <Flag name='solomon islands' />
        Solomon Islands
      </>
    ),
  },
  {
    key: 'so',
    value: 'Somalia',
    text: (
      <>
        <Flag name='somalia' />
        Somalia
      </>
    ),
  },
  {
    key: 'za',
    value: 'South Africa',
    text: (
      <>
        <Flag name='south africa' />
        South Africa
      </>
    ),
  },
  {
    key: 'gs',
    value: 'South Georgia and the South Sandwich Islands',
    text: (
      <>
        <Flag name='south georgia and the south sandwich islands' />
        South Georgia and the South Sandwich Islands
      </>
    ),
  },
  {
    key: 'kr',
    value: 'South Korea',
    text: (
      <>
        <Flag name='south korea' />
        South Korea
      </>
    ),
  },
  {
    key: 'es',
    value: 'Spain',
    text: (
      <>
        <Flag name='spain' />
        Spain
      </>
    ),
  },
  {
    key: 'lk',
    value: 'Sri Lanka',
    text: (
      <>
        <Flag name='sri lanka' />
        Sri Lanka
      </>
    ),
  },
  {
    key: 'sd',
    value: 'Sudan',
    text: (
      <>
        <Flag name='sudan' />
        Sudan
      </>
    ),
  },
  {
    key: 'ss',
    value: 'South Sudan',
    text: (
      <>
        <Flag name='south sudan' />
        South Sudan
      </>
    ),
  },
  {
    key: 'sr',
    value: 'Suriname',
    text: (
      <>
        <Flag name='suriname' />
        Suriname
      </>
    ),
  },
  {
    key: 'sj',
    value: 'Svalbard and Jan Mayen',
    text: (
      <>
        <Flag name='svalbard and jan mayen' />
        Svalbard and Jan Mayen
      </>
    ),
  },
  {
    key: 'sz',
    value: 'Swaziland',
    text: (
      <>
        <Flag name='swaziland' />
        Swaziland
      </>
    ),
  },
  {
    key: 'se',
    value: 'Sweden',
    text: (
      <>
        <Flag name='sweden' />
        Sweden
      </>
    ),
  },
  {
    key: 'ch',
    value: 'Switzerland',
    text: (
      <>
        <Flag name='switzerland' />
        Switzerland
      </>
    ),
  },
  {
    key: 'sy',
    value: 'Syria',
    text: (
      <>
        <Flag name='syria' />
        Syria
      </>
    ),
  },
  {
    key: 'tw',
    value: 'Taiwan',
    text: (
      <>
        <Flag name='taiwan' />
        Taiwan
      </>
    ),
  },
  {
    key: 'tj',
    value: 'Tajikistan',
    text: (
      <>
        <Flag name='tajikistan' />
        Tajikistan
      </>
    ),
  },
  {
    key: 'tz',
    value: 'Tanzania',
    text: (
      <>
        <Flag name='tanzania' />
        Tanzania
      </>
    ),
  },
  {
    key: 'th',
    value: 'Thailand',
    text: (
      <>
        <Flag name='thailand' />
        Thailand
      </>
    ),
  },
  {
    key: 'tg',
    value: 'Togo',
    text: (
      <>
        <Flag name='togo' />
        Togo
      </>
    ),
  },
  {
    key: 'tk',
    value: 'Tokelau',
    text: (
      <>
        <Flag name='tokelau' />
        Tokelau
      </>
    ),
  },
  {
    key: 'to',
    value: 'Tonga',
    text: (
      <>
        <Flag name='tonga' />
        Tonga
      </>
    ),
  },
  {
    key: 'tt',
    value: 'Trinidad and Tobago',
    text: (
      <>
        <Flag name='trinidad and tobago' />
        Trinidad and Tobago
      </>
    ),
  },
  {
    key: 'tn',
    value: 'Tunisia',
    text: (
      <>
        <Flag name='tunisia' />
        Tunisia
      </>
    ),
  },
  {
    key: 'tr',
    value: 'Turkey',
    text: (
      <>
        <Flag name='turkey' />
        Turkey
      </>
    ),
  },
  {
    key: 'tm',
    value: 'Turkmenistan',
    text: (
      <>
        <Flag name='turkmenistan' />
        Turkmenistan
      </>
    ),
  },
  {
    key: 'tc',
    value: 'Turks and Caicos Islands',
    text: (
      <>
        <Flag name='turks and caicos islands' />
        Turks and Caicos Islands
      </>
    ),
  },
  {
    key: 'tv',
    value: 'Tuvalu',
    text: (
      <>
        <Flag name='tuvalu' />
        Tuvalu
      </>
    ),
  },
  {
    key: 'ug',
    value: 'Uganda',
    text: (
      <>
        <Flag name='uganda' />
        Uganda
      </>
    ),
  },
  {
    key: 'ua',
    value: 'Ukraine',
    text: (
      <>
        <Flag name='ukraine' />
        Ukraine
      </>
    ),
  },
  {
    key: 'ae',
    value: 'United Arab Emirates',
    text: (
      <>
        <Flag name='united arab emirates' />
        United Arab Emirates
      </>
    ),
  },
  {
    key: 'gb',
    value: 'United Kingdom',
    text: (
      <>
        <Flag name='united kingdom' />
        United Kingdom
      </>
    ),
  },
  {
    key: 'us',
    value: 'United States',
    text: (
      <>
        <Flag name='united states' />
        United States
      </>
    ),
  },
  {
    key: 'um',
    value: 'United States Minor Outlying Islands',
    text: (
      <>
        <Flag name='united states minor outlying islands' />
        United States Minor Outlying Islands
      </>
    ),
  },
  {
    key: 'uy',
    value: 'Uruguay',
    text: (
      <>
        <Flag name='uruguay' />
        Uruguay
      </>
    ),
  },
  {
    key: 'uz',
    value: 'Uzbekistan',
    text: (
      <>
        <Flag name='uzbekistan' />
        Uzbekistan
      </>
    ),
  },
  {
    key: 'vu',
    value: 'Vanuatu',
    text: (
      <>
        <Flag name='vanuatu' />
        Vanuatu
      </>
    ),
  },
  {
    key: 'va',
    value: 'Vatican City',
    text: (
      <>
        <Flag name='vatican city' />
        Vatican City
      </>
    ),
  },
  {
    key: 've',
    value: 'Venezuela',
    text: (
      <>
        <Flag name='venezuela' />
        Venezuela
      </>
    ),
  },
  {
    key: 'vn',
    value: 'Vietnam',
    text: (
      <>
        <Flag name='vietnam' />
        Vietnam
      </>
    ),
  },
  {
    key: 'vg',
    value: 'British Virgin Islands',
    text: (
      <>
        <Flag name='british virgin islands' />
        British Virgin Islands
      </>
    ),
  },
  {
    key: 'vi',
    value: 'US Virgin Islands',
    text: (
      <>
        <Flag name='us virgin islands' />
        US Virgin Islands
      </>
    ),
  },
  {
    key: 'wf',
    value: 'Wallis and Futuna',
    text: (
      <>
        <Flag name='wallis and futuna' />
        Wallis and Futuna
      </>
    ),
  },
  {
    key: 'eh',
    value: 'Western Sahara',
    text: (
      <>
        <Flag name='western sahara' />
        Western Sahara
      </>
    ),
  },
  {
    key: 'ye',
    value: 'Yemen',
    text: (
      <>
        <Flag name='yemen' />
        Yemen
      </>
    ),
  },
  {
    key: 'zm',
    value: 'Zambia',
    text: (
      <>
        <Flag name='zambia' />
        Zambia
      </>
    ),
  },
  {
    key: 'zw',
    value: 'Zimbabwe',
    text: (
      <>
        <Flag name='zimbabwe' />
        Zimbabwe
      </>
    ),
  },
];

export default countryOptions;
