import { useState, useEffect } from 'react';
import api from '../api';
import { createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './features/auth/authSlice';
import { changeSidebar } from './features/layout/layoutSlice';
import { useNavigate, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const SidebarContext = createContext();

function Menu() {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const userName = userInfo?.name || 'Unknown';
  const userRoles = userInfo?.roles || []; // Assuming roles are stored in userInfo

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/user/isEnable');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
    dispatch(changeSidebar(false));
  };

  const handleActivationRedirect = () => {
    navigate('/users/enable');
  };

  return (
    <div className='min-w-screen h-16 sticky top-0 z-10 flex flex-row items-center border-b border-neutral-800 bg-neutral-900'>
      <div className='w-full mb-1 select-none'>
        <div className='w-fit'>
          <Link to={'/dashboard'}>
            <img className='mx-6 w-auto h-8' src='/PoolLogoAlt.svg' alt='Logo' />
          </Link>
        </div>
      </div>
      <div className='w-full flex justify-end items-center mr-3'>
        {!userRoles.includes('ROLE_ADMIN') && ( // Verifica se o usuário tem a ROLE_ADMIN
          <div className='mx-1 cursor-pointer flex relative' onClick={handleActivationRedirect}>
            <Icon icon='mdi:bell' width='24' height='24' color='white' />

            {users.length > 0 && (
              <span className='absolute right-0 top-0 text-sm font-black text-white select-none px-1 rounded bg-red-500'>
                {users.length}
              </span>
            )}
          </div>
        )}

        <Link to={'/definições'}>
          <div className='flex flex-row gap-2 mx-6 items-center cursor-pointer'>
            <Icon icon='mingcute:user-4-fill' width='24' height='24' color='white' />
            <span className='text-white text-md font-semibold select-none'>{userName}</span>
          </div>
        </Link>
        <div className='mr-2 cursor-pointer' onClick={handleLogout}>
          <Icon icon='material-symbols:logout' width='24' height='24' color='white' />
        </div>
      </div>
    </div>
  );
}

export default Menu;
