import React, { useState, useEffect } from 'react';
import api from '../../api';
import { useMediaQuery } from 'react-responsive';
import UserListBox from './UserListBox';
import ChartBox from './ChartBox';
import WelcomeBox from './WelcomeBox';
import BarChartBox from './BarChartBox';
import PieChartBox from './PieChartBox';
import {
  chartBoxGames,
  chartBoxLeague,
  chartBoxUser,
  userTypeChart,
  matchBoxGames,
} from '../data/DataComponent';
import MatchBox from './MatchBox';

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [league, setLeague] = useState([]);
  const [upcomingGames, setUpcomingGames] = useState([]);
  const [games, setGames] = useState([]);
  const isBodyJunction = useMediaQuery({ query: '(max-width: 1612px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1286px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 800px)' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await api.get('/user/list');
        setUsers(usersResponse.data);

        const leagueResponse = await api.get('/league/list');
        setLeague(leagueResponse.data);

        const gamesResponse = await api.get('/games/list');
        setGames(gamesResponse.data);

        const upcomingGamesResponse = await api.get('/games/upcoming');
        setUpcomingGames(upcomingGamesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const userChartData = chartBoxUser(users);
  const leagueChartData = chartBoxLeague(league);
  const gamesChartData = chartBoxGames(games);
  const userTypeChartObj = userTypeChart(users);
  const matchBoxGamesObj = matchBoxGames(upcomingGames);

  return (
    <>
      {!isBodyJunction && !isSmallScreen && !isExtraSmallScreen && (
        <>
          <div className='grid gap-5 grid-cols-4 auto-rows-[180px] p-5'>
            <div className='p-5 rounded-2xl bg-neutral-800 border border-solid border-neutral-700 row-span-3 col-span-1'>
              <UserListBox />
            </div>

            <div className='rounded-2xl bg-violet-600 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <WelcomeBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...userChartData} />
            </div>

            <div className='p-6 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-1'>
              <PieChartBox
                color={userTypeChartObj.color}
                icon={userTypeChartObj.icon}
                title={userTypeChartObj.title}
                linkPath={userTypeChartObj.linkPath}
                dataKey={userTypeChartObj.dataKey}
                number={userTypeChartObj.number}
                percentage={userTypeChartObj.percentage}
                chartData={userTypeChartObj.chartData}
              />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...leagueChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...gamesChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-2 col-span-2'>
              <MatchBox {...matchBoxGamesObj} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <BarChartBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <BarChartBox {...gamesChartData} />
            </div>
          </div>
        </>
      )}

      {isBodyJunction && !isSmallScreen && !isExtraSmallScreen && (
        <>
          <div className='grid gap-5 grid-cols-3 auto-rows-[180px] p-5'>
            <div className='p-5 rounded-2xl bg-neutral-800 border border-solid border-neutral-700 row-span-3 col-span-1'>
              <UserListBox />
            </div>

            <div className='rounded-2xl bg-violet-600 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <WelcomeBox {...userChartData} />
            </div>

            <div className='p-6 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-1'>
              <PieChartBox
                color={userTypeChartObj.color}
                icon={userTypeChartObj.icon}
                title={userTypeChartObj.title}
                linkPath={userTypeChartObj.linkPath}
                dataKey={userTypeChartObj.dataKey}
                number={userTypeChartObj.number}
                percentage={userTypeChartObj.percentage}
                chartData={userTypeChartObj.chartData}
              />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <BarChartBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <BarChartBox {...gamesChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-2'>
              <MatchBox {...matchBoxGamesObj} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...leagueChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...gamesChartData} />
            </div>
          </div>
        </>
      )}

      {isBodyJunction && isSmallScreen && !isExtraSmallScreen && (
        <>
          <div className='grid gap-5 grid-cols-2 auto-rows-[180px] p-5'>
            <div className='p-5 rounded-2xl bg-neutral-800 border border-solid border-neutral-700 row-span-3 col-span-1'>
              <UserListBox />
            </div>

            <div className='rounded-2xl bg-violet-600 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <WelcomeBox {...userChartData} />
            </div>

            <div className='p-6 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-1'>
              <PieChartBox
                color={userTypeChartObj.color}
                icon={userTypeChartObj.icon}
                title={userTypeChartObj.title}
                linkPath={userTypeChartObj.linkPath}
                dataKey={userTypeChartObj.dataKey}
                number={userTypeChartObj.number}
                percentage={userTypeChartObj.percentage}
                chartData={userTypeChartObj.chartData}
              />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...leagueChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...gamesChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-2'>
              <MatchBox {...matchBoxGamesObj} />
            </div>
          </div>
        </>
      )}

      {isExtraSmallScreen && (
        <>
          <div className='grid gap-5 grid-cols-1 auto-rows-[180px] p-5'>
            <div className='rounded-2xl bg-violet-600 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <WelcomeBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...userChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...leagueChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-1 col-span-1'>
              <ChartBox {...gamesChartData} />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 border border-solid border-neutral-700 row-span-3 col-span-1'>
              <UserListBox />
            </div>

            <div className='p-6 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-1'>
              <PieChartBox
                color={userTypeChartObj.color}
                icon={userTypeChartObj.icon}
                title={userTypeChartObj.title}
                linkPath={userTypeChartObj.linkPath}
                dataKey={userTypeChartObj.dataKey}
                number={userTypeChartObj.number}
                percentage={userTypeChartObj.percentage}
                chartData={userTypeChartObj.chartData}
              />
            </div>

            <div className='p-5 rounded-2xl bg-neutral-800 text-white border border-solid border-neutral-700 row-span-3 col-span-1'>
              <MatchBox {...matchBoxGamesObj} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
