import React from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import { Flag } from 'semantic-ui-react';

const MatchBox = (props) => {
  const { color, icon, title, linkPath, chartData } = props;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
    const formattedDateTime = `${formattedDate} | ${formattedTime}`;
    return formattedDateTime;
  };

  return (
    <div className='flex h-full w-full'>
      <div className='flex flex-1 w-full relative flex-col gap-6'>
        <div className='flex items-center gap-3'>
          <Icon icon={icon} width='32' height='32' color='white' />
          <span>{title}</span>
        </div>
        <div className='grid gap-8 grid-cols-3 w-full overflow-y-auto'>
          {chartData &&
            chartData.map((game, index) => {
              const player1 = game.Hands[0].User1;
              const player2 = game.Hands[0].User2;
              return (
                <React.Fragment key={index}>
                  {/* PLAYER 1 */}
                  <div className='row-span-1 col-span-1 pl-2'>
                    {player1 && (
                      <div className='flex flex-row items-center gap-5'>
                        <img
                          className='w-16 h-16 rounded-full object-cover'
                          src={`https://api.ligas.purplews.live/photos/` + player1.Photo}
                          alt={player1.name}
                        />
                        <div className='flex flex-col text-start gap-1'>
                          <span>{player1.name}</span>
                          <span>
                            <Flag name={player1.Nacionality} />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* INFO */}
                  <div className='row-span-1 col-span-1'>
                    <div className='flex flex-col items-center text-xs gap-1 justify-center'>
                      <span>{game.LeagueName}</span>
                      <span>{game.Stream}</span>
                      <span className='text-base'>{formatDate(game.Date)}</span>
                    </div>
                  </div>

                  {/* PLAYER 2 */}
                  <div className='row-span-1 col-span-1 pr-2'>
                    {player2 && (
                      <div className='flex flex-row items-center gap-5 justify-end'>
                        <div className='flex flex-col text-end gap-1'>
                          <span>{player2.name}</span>
                          <span>
                            <Flag name={player2.Nacionality} />
                          </span>
                        </div>
                        <img
                          className='w-16 h-16 rounded-full object-cover'
                          src={`https://api.ligas.purplews.live/photos/` + player2.Photo}
                          alt={player2.name}
                        />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MatchBox;
