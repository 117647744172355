const disponibilidadeOptions = [
  {
    key: 'aberto',
    value: 'aberto',
    text: (
      <>
        <i className='aberto' />
        Aberto
      </>
    ),
  },
  {
    key: 'fechado',
    value: 'fechado',
    text: (
      <>
        <i className='fechado' />
        Fechado
      </>
    ),
  },
];

export default disponibilidadeOptions;
