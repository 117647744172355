import React, { useEffect, useState } from 'react';
import DataTable from '../dataTable/dataTable';
import api from '../../api';
import { matchBoxGames } from '../data/DataComponent';
import { Icon } from '@iconify/react';
import GameCreate from './GameCreate';
import GamesEdit from './GameEdit';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Game = () => {
  const [games, setGames] = useState([]);
  const [newGame, setNewGame] = useState(false);
  const [editGameId, setEditGameId] = useState(null);
  const [gameIdToDelete, setGameIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await api.get('/games/list');
        let filteredGames = response.data;

        if (userInfo.type === 'admin') {
          filteredGames = filteredGames.filter((game) =>
            userInfo.leagues.some((league) => game.League_id === league.id)
          );
        }

        setGames(filteredGames);
      } catch (error) {
        console.error('Erro ao buscar jogos:', error);
      }
    };

    fetchGames();
  }, [userInfo]);

  const newGamePopUp = () => {
    setNewGame(true);
  };

  const closeGamePopUp = () => {
    setNewGame(false);
  };

  const editGame = (gameId) => {
    setEditGameId(gameId);
  };

  const closeEditGame = () => {
    setEditGameId(null);
  };

  const onViewGame = (userId) => {
    navigate(`/game/${userId}`);
  };

  const handleSave = async () => {
    try {
      const response = await api.get('/games/list');
      let filteredGames = response.data;

      if (userInfo.type === 'admin') {
        filteredGames = filteredGames.filter((game) =>
          userInfo.leagues.some((league) => game.League_id === league.id)
        );
      }

      setGames(filteredGames);
    } catch (error) {
      console.error('Erro ao buscar jogos:', error);
    }
  };

  const confirmDeleteGame = (gameId) => {
    setGameIdToDelete(gameId);
    setShowDeleteModal(true);
  };

  const deleteGames = async () => {
    try {
      await api.delete(`/games/delete/${gameIdToDelete}`);
      const updatedGames = games.filter((item) => item.id !== gameIdToDelete);
      setGames(updatedGames);
      setShowDeleteModal(false);
      toast.success('Jogo apagado com sucesso!');
    } catch (error) {
      console.error('Erro ao apagar jogo:', error);
      toast.error('Erro ao apagar jogo');
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setGameIdToDelete(null);
  };

  const gameData = matchBoxGames(games);

  return (
    <div className='relative flex flex-col w-full h-full p-10 bg-neutral-800'>
      {newGame && (
        <div className='absolute bg-black opacity-75 inset-0 flex items-center justify-center z-40'>
          <div className='bg-neutral-800 opacity-100 z-50 p-10 rounded-lg'>
            <GameCreate onClose={closeGamePopUp} />
          </div>
        </div>
      )}

      {editGameId && <GamesEdit gameId={editGameId} onClose={closeEditGame} onSave={handleSave} />}

      {showDeleteModal && (
        <div className='absolute bg-black bg-opacity-75 inset-0 flex items-center justify-center z-40'>
          <div className='bg-neutral-800 z-50 p-5 rounded-lg w-96'>
            <h2 className='text-white mb-4'>Tem a certeza que deseja apagar este jogo?</h2>
            <div className='flex justify-center'>
              <button
                onClick={deleteGames}
                className='p-2 mr-5 rounded bg-red-500 hover:bg-red-600 active:bg-red-700 text-white'
              >
                Confirmar
              </button>
              <button
                onClick={cancelDelete}
                className='p-2 ml-5 rounded bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white'
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-row text-white justify-between items-center mb-5'>
        <div className='flex flex-row gap-3 items-center'>
          <Icon icon={gameData.icon} width='32' height='32' color='white' />
          <span className='text-xl font-bold'>{gameData.listTitle}</span>
        </div>
        <div>
          <button
            onClick={newGamePopUp}
            className='p-3 rounded-lg w-auto bg-blue-600 hover:bg-blue-700 active:bg-blue-800'
          >
            Novo Jogo
          </button>
        </div>
      </div>
      <DataTable
        tableHeads={gameData.tableHeads}
        tableRows={gameData.tableData}
        onEdit={editGame}
        onDelete={confirmDeleteGame}
        onView={onViewGame}
      />
    </div>
  );
};

export default Game;
