import React, { useState, useEffect } from 'react';
import api from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserEdit = ({ userId, onClose, onSave }) => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    Phone: '',
    Age: '',
  });
  const [modifiedFields, setModifiedFields] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`/user/${userId}`);
        setUserData({
          name: response.data.name,
          email: response.data.email,
          Phone: response.data.phone,
          Age: response.data.age,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'Phone') {
      if (/^\d{0,9}$/.test(value)) {
        setUserData((prevData) => ({ ...prevData, [name]: value }));
        setModifiedFields((prevFields) => ({ ...prevFields, [name]: value }));
      }
    } else {
      setUserData((prevData) => ({ ...prevData, [name]: value }));
      setModifiedFields((prevFields) => ({ ...prevFields, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(modifiedFields).length === 0) {
      onClose();
      return;
    }

    try {
      await api.post(`/user/update/${userId}`, JSON.stringify(modifiedFields), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      onSave();
      onClose();
      toast.success('Usuário atualizado com sucesso!');
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Erro ao atualizar usuário');
    }
  };

  return (
    <div className='absolute bg-black opacity-75 inset-0 flex items-center justify-center z-40'>
      <div className='bg-neutral-800 opacity-100 z-50 p-10 rounded-lg'>
        <h2 className='text-xl font-bold text-white mb-4'>Editar Usuário</h2>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='name'>
              Nome
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={userData.name}
              onChange={handleChange}
              className='w-full p-2 rounded'
              minLength={3}
              pattern='^[a-zA-ZÀ-ÿ ]+$'
              title='Nome deve conter no mínimo 3 caracteres e apenas letras.'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='email'>
              Email
            </label>
            <input
              type='email'
              id='email'
              name='email'
              value={userData.email}
              onChange={handleChange}
              className='w-full p-2 rounded'
              pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]+)?$'
              title='Insira um email válido (ex: exemplo@dominio.com)'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Phone'>
              Telefone
            </label>
            <input
              type='text'
              id='Phone'
              name='Phone'
              value={userData.Phone}
              onChange={handleChange}
              className='w-full p-2 rounded'
              pattern='[0-9]{9}'
              title='Formato: 9 números'
              maxLength='9'
              minLength='9'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-white mb-2' htmlFor='Age'>
              Idade
            </label>
            <input
              type='number'
              id='Age'
              name='Age'
              value={userData.Age}
              onChange={handleChange}
              className='w-full p-2 rounded'
              min='16'
              max='90'
            />
          </div>
          <div className='flex justify-end'>
            <button
              type='button'
              onClick={onClose}
              className='p-2 rounded bg-gray-500 hover:bg-gray-600 text-white mr-2'
            >
              Cancelar
            </button>
            <button type='submit' className='p-2 rounded bg-blue-500 hover:bg-blue-600 text-white'>
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserEdit;
