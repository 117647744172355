import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const PieChartBox = ({ color, icon, title, number, linkPath, chartData }) => {
    // Ensure chartData is defined and is an array before mapping over it
    const safeChartData = Array.isArray(chartData) ? chartData : [];

    // Extract unique fill colors from chartData
    const uniqueFillColors = [...new Set(safeChartData.map(entry => entry.fill))];

    // Use the extracted colors as the COLORS array
    const COLORS = uniqueFillColors;

    return (
        <div className='flex flex-col justify-between h-full'>
            <div className="flex flex-col justify-between">
                <div className="flex relative items-center gap-3">
                    <Icon icon={icon} width="32" height="32" color='white' />
                    <span>{title}</span>
                    <Link className='absolute right-0' style={{ color: color }} to={linkPath}>Ver tudo</Link>
                    <h1 className='absolute left-5 top-5'>{number}</h1>
                </div>
            </div>
            <div className='flex items-center justify-center'>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Tooltip
                            contentStyle={{
                                background: 'white',
                                borderRadius: '5px',
                            }}
                        />
                        <Pie
                            data={safeChartData}
                            innerRadius="70%"
                            outerRadius="90%"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {safeChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} label />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className='flex justify-between gap-2 text-base'>
                {safeChartData.map((entry, index) => (
                    <div key={`entry-${index}`}>
                        <div className='flex items-center'>
                            <div
                                className='w-4 h-4 mr-2'
                                style={{ backgroundColor: COLORS[index % COLORS.length] }}
                            />
                            <span>{entry.name}</span>
                        </div>
                        <span>{entry.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PieChartBox;
