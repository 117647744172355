import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from './features/auth/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery({ query: '(max-width: 1350px)' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(loginUser({ email, password })).then((res) => {
        if (res.payload?.result) {
          toast.success('Login efetuado com sucesso.');
          navigate('/dashboard');
        } else {
          toast.error('Login não efetuado');
        }
      });
    } catch {
      toast.error('Login Failed');
    }
  };

  return (
    <>
      {isMediumScreen ? (
        <div
          className='w-full h-full flex items-center justify-center'
        >
          <div className='max-w-lg w-full rounded-lg p-8 space-y-6'>
            <div className='flex w-full justify-center pb-5'>
              <img className='mx-8 w-auto h-20' src='/PoolLogoAlt.svg' />
            </div>
            <form onSubmit={handleSubmit} className='space-y-4'>
              <input type='hidden' name='remember' value='true' />
              <div>
                <label htmlFor='email' className='sr-only'>
                  {'Email'}
                </label>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
                  placeholder={'Email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor='password' className='sr-only'>
                  {'Password'}
                </label>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
                  placeholder={'Password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border-2 border-transparent text-sm font-bold rounded-md text-neutral-800 bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-400 hover:border-neutral-300 active:border-neutral-400'
                >
                  {'Login'}
                </button>
              </div>
            </form>
            <div className='flex justify-center'>
              <Link to='/adminregister' className='text-neutral-200 hover:text-neutral-300'>
                Não tenho uma conta
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='relative w-full h-full flex items-center justify-start'
          style={{
            backgroundImage: 'url(/LoginBackground.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className='flex flex-grow flex-shrink max-w-96'></div>
          <div className='max-w-lg w-full rounded-lg p-8 space-y-6'>
            <div className='flex w-full justify-center pb-5'>
              <img className='mx-8 w-auto h-20' src='/PoolLogoAlt.svg' />
            </div>
            <form onSubmit={handleSubmit} className='space-y-4'>
              <input type='hidden' name='remember' value='true' />
              <div>
                <label htmlFor='email' className='sr-only'>
                  {'Email'}
                </label>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
                  placeholder={'Email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor='password' className='sr-only'>
                  {'Password'}
                </label>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='block shadow-inner w-full px-6 py-3 text-white border border-neutral-800 rounded-md focus:outline-none focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm bg-neutral-900'
                  placeholder={'Password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border-2 border-transparent text-sm font-bold rounded-md text-neutral-800 bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-400 hover:border-neutral-300 active:border-neutral-400'
                >
                  {'Login'}
                </button>
              </div>
            </form>
            <div className='flex justify-center'>
              <Link to='/adminregister' className='text-neutral-200 hover:text-neutral-300'>
                Não tenho uma conta
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginForm;
