import React, { useState } from 'react';
import api from '../../api';
import slotsOptions from './slots';
import disponibilidadeOptions from './disponibilidade';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeagueCreate = ({ onClose }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Slots: '',
    Availability: '',
    Image: '',
    Inicio: '',
    Fim: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      await api.post('/league/create', formDataToSend);
      setFormSubmitted(true);
      setFormData({
        Name: '',
        Slots: '',
        Availability: '',
        Image: '',
        Inicio: '',
        Fim: '',
      });
      toast.success('Liga criada com sucesso!');
    } catch (error) {
      console.error('Erro ao criar liga:', error);
      toast.error('Erro ao criar liga');
    }
  };

  return (
    <div className='max-w-5xl mx-auto p-10 bg-neutral-800 rounded-2xl shadow-lg'>
      <div className='flex flex-row justify-between items-center'>
        <div>
          <h2 className='text-4xl font-bold mb-4 text-white'>Criar Liga</h2>
        </div>
        <div className='hover:cursor-pointer' onClick={onClose}>
          <Icon icon='iconamoon:close' width='48' height='48' color='white' />
        </div>
      </div>
      {formSubmitted ? (
        <div className='text-white'>Liga criada com sucesso!</div>
      ) : (
        <form onSubmit={handleSubmit} className='space-y-4'>
          <div>
            <label htmlFor='Name' className='block font-semibold text-white'>
              Nome:
            </label>
            <input
              type='text'
              id='Name'
              name='Name'
              value={formData.Name}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            />
          </div>
          <div>
            <label htmlFor='Slots' className='block font-semibold text-white'>
              Vagas:
            </label>
            <select
              id='Slots'
              name='Slots'
              value={formData.Slots}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione as vagas
              </option>
              {slotsOptions.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='Availability' className='block font-semibold text-white'>
              Disponibilidade:
            </label>
            <select
              id='Availability'
              name='Availability'
              value={formData.Availability}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            >
              <option value='' disabled>
                Selecione a disponibilidade da Liga
              </option>
              {disponibilidadeOptions.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor='Image' className='block font-semibold text-white'>
              Foto:
            </label>
            <input
              type='file'
              id='Image'
              name='Image'
              onChange={handleChange}
              className='mt-1 p-2 border bg-white border-neutral-300 rounded-md w-full'
              required
            />
          </div>
          <div>
            <label htmlFor='Inicio' className='block font-semibold text-white'>
              Início da Liga:
            </label>
            <input
              type='datetime-local'
              id='Inicio'
              name='Inicio'
              value={formData.Inicio}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            />
          </div>
          <div>
            <label htmlFor='Fim' className='block font-semibold text-white'>
              Fim da Liga:
            </label>
            <input
              type='datetime-local'
              id='Fim'
              name='Fim'
              value={formData.Fim}
              onChange={handleChange}
              className='mt-1 p-2 border border-neutral-800 rounded-md w-full'
              required
            />
          </div>
          <div>
            <button
              type='submit'
              className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600'
            >
              Criar Liga
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default LeagueCreate;
