import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import api from '../../api';
import LeagueTable from '../LeagueTable';
import { useMediaQuery } from 'react-responsive';
import { Icon } from '@iconify/react';
import LeagueEdit from './LeagueEdit';
import { useNavigate } from 'react-router-dom';

const LeagueProfile = () => {
  const { id } = useParams();
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [loadingGames, setLoadingGames] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState({});
  const [loadingUnregister, setLoadingUnregister] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [openLeagues, setOpenLeagues] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableUsersModalVisible, setAvailableUsersModalVisible] = useState(false);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [users, setUsers] = useState([]);
  const isBodyJunction = useMediaQuery({ query: '(max-width: 1612px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1286px)' });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeagueDetails = async () => {
      try {
        const response = await api.get(`/league/details/${id}`);
        setLeague(response.data);
      } catch (error) {
        console.error('Error fetching league details:', error);
      }
    };

    const fetchAvailableUsers = async () => {
      try {
        const response = await api.get('/league/listAvailableUsers');
        setAvailableUsers(response.data);
      } catch (error) {
        console.error('Error fetching available users:', error);
        toast.error('Erro ao buscar usuários disponíveis');
      }
    };

    fetchLeagueDetails();
    fetchAvailableUsers();
  }, [id, userInfo]);

  const startLeague = async () => {
    setLoading(true);
    try {
      await api.post(
        '/league/StartLeague',
        { league_id: id },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error('Error starting league:', error);
      if (error.response && error.response.status === 404) {
        toast.error('A liga já se encontra aberta');
      } else {
        toast.error('Erro ao abrir a liga.');
      }
      setLoading(false);
    }
  };

  const endLeague = async () => {
    setLoadingEnd(true);
    try {
      await api.post(
        '/league/EndLeague',
        { league_id: id },
        { headers: { 'Content-Type': 'application/json' } }
      );
      window.location.reload();
      setLoadingEnd(false);
    } catch (error) {
      console.error('Error ending league:', error);
      if (error.response && error.response.status === 404) {
        toast.error('A liga já se encontra fechada');
      } else {
        toast.error('Erro ao fechar a liga.');
      }
      setLoadingEnd(false);
    }
  };

  const createAutomaticGames = async () => {
    setLoadingGames(true);
    try {
      await api.post(
        `/games/createGames/${id}`,
        { Location: league.Name, Date: league.start_date },
        { headers: { 'Content-Type': 'application/json' } }
      );
      toast.success('Jogos criados com sucesso!');
      setLoadingGames(false);
      window.location.reload();
    } catch (error) {
      console.error('Error creating games:', error);
      toast.error('Erro ao criar jogos automaticamente.');
      setLoadingGames(false);
    }
  };

  const handleRegister = async (userId) => {
    setLoadingRegister((prev) => ({ ...prev, [userId]: true }));
    try {
      const formData = new FormData();
      formData.append('leagueId', id);
      formData.append('userId', userId);

      await api.post('/league/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast.success('Usuário registrado com sucesso!');
      setLoadingRegister((prev) => ({ ...prev, [userId]: false }));
      window.location.reload();
    } catch (error) {
      console.error('Error registering user:', error);
      toast.error('Erro ao registrar usuário');
      setLoadingRegister((prev) => ({ ...prev, [userId]: false }));
    }
  };

  const handleUnregister = async (userId) => {
    setLoadingUnregister((prev) => ({ ...prev, [userId]: true }));
    try {
      const formData = new FormData();
      formData.append('leagueId', id);
      formData.append('userId', userId);

      await api.post(`/league/unregister/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast.success('Usuário desinscrito com sucesso!');
      setLoadingUnregister((prev) => ({ ...prev, [userId]: false }));
      window.location.reload();
    } catch (error) {
      console.error('Error unregistering user:', error);
      toast.error('Erro ao desinscrever usuário');
      setLoadingUnregister((prev) => ({ ...prev, [userId]: false }));
    }
  };

  const handleActionClick = (action) => {
    setActionType(action);
    setModalVisible(true);
  };

  const handleConfirmAction = () => {
    setModalVisible(false);
    if (actionType === 'start') {
      startLeague();
    } else if (actionType === 'end') {
      endLeague();
    } else if (actionType === 'createGames') {
      createAutomaticGames();
    }
  };

  const openAvailableUsersModal = () => {
    setAvailableUsersModalVisible(true);
  };

  const closeAvailableUsersModal = () => {
    setAvailableUsersModalVisible(false);
  };

  const handleRegisterAvailableUser = async (userId) => {
    await handleRegister(userId);
    closeAvailableUsersModal();
  };

  if (!league) {
    return <div className='flex items-center justify-center h-screen text-white'>Loading...</div>;
  }

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteLeague = async () => {
    try {
      await api.delete(`/league/delete/${id}`);
      toast.success('Liga apagada com sucesso!');
      navigate('/league/list');
    } catch (error) {
      console.error('Erro ao apagar liga:', error);
      toast.error('Erro ao apagar liga');
    }
  };

  return (
    <>
      {isBodyJunction && (
        <>
          <div className='w-full h-full grid grid-cols-1 auto-rows-[400px] gap-5 mx-auto p-4'>
            <div className='relative col-span-2 border border-neutral-700 rounded-xl px-8 py-6'>
              <div className='flex items-center justify-between mb-4'>
                <h1 className='text-3xl font-bold text-white'>{league.Name}</h1>
                <img
                  src={`https://api.ligas.purplews.live/photos/${league.Image}`}
                  alt='League'
                  className='w-28 h-28 rounded-full object-cover'
                />
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Id:</span> {league.id}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Nome:</span> {league.Name}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vagas Totais:</span> {league.Slots}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vagas Disponíveis:</span>{' '}
                    {league.SlotsAvailable}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vagas Ocupadas:</span> {league.SlotsFilled}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Inscrições:</span>{' '}
                    {league.Availability ? 'Abertas' : 'Fechadas'}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Começo da Liga:</span> {league.start_date}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Final da Liga:</span> {league.end_date}
                  </p>
                </div>
                <div className='absolute bottom-5 right-5 flex flex-col gap-2'>
                  <div className='flex flex-col gap-1'>
                    <button
                      onClick={() => handleActionClick('start')}
                      className={`p-1 rounded-lg mb-2 w-auto text-white ${
                        loading
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-green-600 hover:bg-green-700'
                      }`}
                      disabled={loading}
                    >
                      {loading ? 'Aguarde...' : 'Abrir Liga'}
                    </button>
                    <button
                      onClick={() => handleActionClick('end')}
                      className={`p-1 rounded-lg mb-2 w-auto text-white ${
                        loadingEnd
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-red-600 hover:bg-red-700'
                      }`}
                      disabled={loadingEnd}
                    >
                      {loadingEnd ? 'Aguarde...' : 'Fechar Liga'}
                    </button>
                    <button
                      onClick={openAvailableUsersModal}
                      className='p-1 rounded-lg w-auto mb-2 text-white bg-yellow-600 hover:bg-yellow-700'
                    >
                      Inscrever Usuário
                    </button>
                  </div>

                  <div className='flex flex-row justify-end gap-2'>
                    <button
                      onClick={() => handleActionClick('createGames')}
                      className={`p-1.5 rounded-lg text-white font-bold ${
                        loadingGames
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-blue-600 hover:bg-blue-700'
                      }`}
                      disabled={loadingGames}
                      title='Criar todos os jogos da liga'
                    >
                      {loadingGames ? (
                        <Icon icon='eos-icons:loading' width='24' height='24' color='white' />
                      ) : (
                        <Icon icon='mdi:plus' width='24' height='24' color='white' />
                      )}
                    </button>
                    <button
                      onClick={openEditModal}
                      className='p-1.5 rounded-lg text-white font-bold bg-purple-600 hover:bg-purple-700'
                      title='Editar' // Aqui você define o texto do tooltip
                    >
                      <Icon icon='mdi:pencil-outline' width='24' height='24' color='white' />
                    </button>
                    <button
                      onClick={handleDeleteLeague}
                      className='p-1.5 rounded-lg text-white font-bold bg-black hover:bg-neutral-900'
                      title='Apagar'
                    >
                      <Icon icon='ph:trash' width='24' height='24' color='white' />
                    </button>
                  </div>

                  {isEditModalOpen && (
                    <LeagueEdit
                      leagueId={id}
                      onClose={closeEditModal}
                      onSave={() => {
                        closeEditModal();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className='col-span-1 border border-neutral-700 rounded-xl px-5 py-5'>
              <h2 className='text-lg text-white font-semibold mb-4'>Usuários Inscritos</h2>
              <ul
                className='flex flex-col overflow-auto divide-gray-200'
                style={{ height: '330px' }}
              >
                {league.users
                  .filter((user) => user.type === 'captain' || user.type === 'player')
                  .map((user) => (
                    <li key={user.id} className='p-4 flex items-center justify-between'>
                      <div className='flex flex-row'>
                        <div className='flex flex-col'>
                          <img
                            src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                            alt={user.name}
                            className='w-16 h-16 rounded-full object-cover mr-4'
                          />
                        </div>
                        <div className='flex flex-col'>
                          <p className='text-lg font-semibold text-white'>{user.name}</p>
                          <p className='text-sm text-white mb-2'>Id: {user.id}</p>
                          <p className='text-sm text-white mb-2'>
                            Contactos: {user.email} | {user.Phone}
                          </p>
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <button
                          onClick={() => handleUnregister(user.id)}
                          className={`p-2 rounded-lg text-white font-bold ${
                            loadingUnregister[user.id]
                              ? 'bg-gray-400 cursor-not-allowed'
                              : 'bg-red-600 hover:bg-red-700'
                          }`}
                          disabled={loadingUnregister[user.id]}
                        >
                          {loadingUnregister[user.id] ? (
                            <Icon icon='eos-icons:loading' width='24' height='24' color='white' />
                          ) : (
                            <Icon icon='iconamoon:close' width='24' height='24' color='white' />
                          )}
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <div className='col-span-1 row-span-2 border border-neutral-700 rounded-xl overflow-auto'>
              <LeagueTable selectedLeague={league} />
            </div>

            {modalVisible && (
              <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
                <div className='bg-white rounded-lg p-6'>
                  <h2 className='text-xl font-bold mb-4'>Confirmação</h2>
                  <p className='mb-4'>
                    Tem a certeza de que deseja{' '}
                    {actionType === 'start'
                      ? 'começar'
                      : actionType === 'end'
                      ? 'acabar'
                      : actionType === 'createGames'
                      ? 'criar todos os jogos para a'
                      : actionType === 'register'
                      ? 'registrar o usuário na'
                      : 'desinscrever o usuário da'}{' '}
                    liga?
                  </p>
                  <div className='flex justify-end'>
                    <button
                      onClick={handleConfirmAction}
                      className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2'
                    >
                      Confirmar
                    </button>
                    <button
                      onClick={() => setModalVisible(false)}
                      className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700'
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            )}

            {availableUsersModalVisible && (
              <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
                <div className='bg-white rounded-lg p-6 w-96'>
                  <h2 className='text-xl font-bold mb-4'>Usuários Disponíveis</h2>
                  <ul className='max-h-64 overflow-y-auto'>
                    {availableUsers.map((user) => (
                      <li key={user.id} className='py-2 flex items-center justify-between'>
                        <span>
                          {user.id}. {user.name}
                        </span>
                        <button
                          onClick={() => handleRegisterAvailableUser(user.id)}
                          className={`p-2 rounded-lg ${
                            loadingRegister[user.id]
                              ? 'bg-gray-400 cursor-not-allowed'
                              : 'bg-green-600 hover:bg-green-700'
                          }`}
                          disabled={loadingRegister[user.id]}
                        >
                          {loadingRegister[user.id] ? 'Inscrevendo...' : 'Inscrever'}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className='flex justify-end mt-4'>
                    <button
                      onClick={closeAvailableUsersModal}
                      className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700'
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {!isBodyJunction && (
        <>
          <div className='w-full h-full grid grid-cols-5 auto-rows-[400px] gap-5 mx-auto p-4'>
            <div className='relative col-span-2 border border-neutral-700 rounded-xl px-8 py-6'>
              <div className='flex items-center justify-between mb-4'>
                <h1 className='text-3xl font-bold text-white'>{league.Name}</h1>
                <img
                  src={`https://api.ligas.purplews.live/photos/${league.Image}`}
                  alt='League'
                  className='w-28 h-28 rounded-full object-cover'
                />
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Id:</span> {league.id}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Nome:</span> {league.Name}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vagas Totais:</span> {league.Slots}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vagas Disponíveis:</span>{' '}
                    {league.SlotsAvailable}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Vagas Ocupadas:</span> {league.SlotsFilled}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Inscrições:</span>{' '}
                    {league.Availability ? 'Abertas' : 'Fechadas'}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Começo da Liga:</span> {league.start_date}
                  </p>
                  <p className='text-sm text-white mb-2'>
                    <span className='font-semibold'>Final da Liga:</span> {league.end_date}
                  </p>
                </div>
                <div className='absolute bottom-5 right-5 flex flex-col gap-2'>
                  <div className='flex flex-col gap-1'>
                    <button
                      onClick={() => handleActionClick('start')}
                      className={`p-1 rounded-lg mb-2 w-auto text-white ${
                        loading
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-green-600 hover:bg-green-700'
                      }`}
                      disabled={loading}
                    >
                      {loading ? 'Aguarde...' : 'Abrir Liga'}
                    </button>
                    <button
                      onClick={() => handleActionClick('end')}
                      className={`p-1 rounded-lg mb-2 w-auto text-white ${
                        loadingEnd
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-red-600 hover:bg-red-700'
                      }`}
                      disabled={loadingEnd}
                    >
                      {loadingEnd ? 'Aguarde...' : 'Fechar Liga'}
                    </button>
                    <button
                      onClick={openAvailableUsersModal}
                      className='p-1 rounded-lg w-auto mb-2 text-white bg-yellow-600 hover:bg-yellow-700'
                    >
                      Inscrever Usuário
                    </button>
                  </div>

                  <div className='flex flex-row justify-end gap-2'>
                    <button
                      onClick={() => handleActionClick('createGames')}
                      className={`p-1.5 rounded-lg text-white font-bold ${
                        loadingGames
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-blue-600 hover:bg-blue-700'
                      }`}
                      disabled={loadingGames}
                      title='Criar todos os jogos da liga'
                    >
                      {loadingGames ? (
                        <Icon icon='eos-icons:loading' width='24' height='24' color='white' />
                      ) : (
                        <Icon icon='mdi:plus' width='24' height='24' color='white' />
                      )}
                    </button>
                    <button
                      onClick={openEditModal}
                      className='p-1.5 rounded-lg text-white font-bold bg-purple-600 hover:bg-purple-700'
                      title='Editar' // Aqui você define o texto do tooltip
                    >
                      <Icon icon='mdi:pencil-outline' width='24' height='24' color='white' />
                    </button>
                    <button
                      onClick={handleDeleteLeague}
                      className='p-1.5 rounded-lg text-white font-bold bg-black hover:bg-neutral-900'
                      title='Apagar'
                    >
                      <Icon icon='ph:trash' width='24' height='24' color='white' />
                    </button>
                  </div>

                  {isEditModalOpen && (
                    <LeagueEdit
                      leagueId={id}
                      onClose={closeEditModal}
                      onSave={() => {
                        closeEditModal();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='col-span-3 row-span-2 border border-neutral-700 rounded-xl overflow-auto'>
              <LeagueTable selectedLeague={league} />
            </div>

            <div className='col-span-2 border border-neutral-700 rounded-xl px-8 py-5'>
              <h2 className='text-lg text-white font-semibold mb-4'>Usuários Inscritos</h2>
              <ul
                className='flex flex-col overflow-auto divide-gray-200'
                style={{ height: '330px' }}
              >
                {league.users
                  .filter((user) => user.type === 'captain' || user.type === 'player')
                  .map((user) => (
                    <li key={user.id} className='p-4 flex items-center justify-between'>
                      <div className='flex flex-row'>
                        <div className='flex flex-col'>
                          <img
                            src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                            alt={user.name}
                            className='w-20 h-20 rounded-full object-cover mr-4'
                          />
                        </div>
                        <div className='flex flex-col'>
                          <p className='text-lg font-semibold text-white'>{user.name}</p>
                          <p className='text-sm text-white mb-2'>Id: {user.id}</p>
                          <p className='text-sm text-white mb-2'>
                            Contactos: {user.email} | {user.Phone}
                          </p>
                        </div>
                      </div>
                      <div className='flex items-center'>
                        <button
                          onClick={() => handleUnregister(user.id)}
                          className={`p-2 rounded-lg text-white font-bold ${
                            loadingUnregister[user.id]
                              ? 'bg-gray-400 cursor-not-allowed'
                              : 'bg-red-600 hover:bg-red-700'
                          }`}
                          disabled={loadingUnregister[user.id]}
                        >
                          {loadingUnregister[user.id] ? (
                            <Icon icon='eos-icons:loading' width='24' height='24' color='white' />
                          ) : (
                            <Icon icon='iconamoon:close' width='24' height='24' color='white' />
                          )}
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            {modalVisible && (
              <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
                <div className='bg-white rounded-lg p-6'>
                  <h2 className='text-xl font-bold mb-4'>Confirmação</h2>
                  <p className='mb-4'>
                    Tem a certeza de que deseja{' '}
                    {actionType === 'start'
                      ? 'começar'
                      : actionType === 'end'
                      ? 'acabar'
                      : actionType === 'createGames'
                      ? 'criar todos os jogos para a'
                      : actionType === 'register'
                      ? 'registrar o usuário na'
                      : 'desinscrever o usuário da'}{' '}
                    liga?
                  </p>
                  <div className='flex justify-end'>
                    <button
                      onClick={handleConfirmAction}
                      className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2'
                    >
                      Confirmar
                    </button>
                    <button
                      onClick={() => setModalVisible(false)}
                      className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700'
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            )}

            {availableUsersModalVisible && (
              <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center'>
                <div className='bg-white rounded-lg p-6 w-96'>
                  <h2 className='text-xl font-bold mb-4'>Usuários Disponíveis</h2>
                  <ul className='max-h-64 overflow-y-auto'>
                    {availableUsers.map((user) => (
                      <li key={user.id} className='py-2 flex items-center justify-between'>
                        <span>
                          {user.id}. {user.name}
                        </span>
                        <button
                          onClick={() => handleRegisterAvailableUser(user.id)}
                          className={`p-2 rounded-lg ${
                            loadingRegister[user.id]
                              ? 'bg-gray-400 cursor-not-allowed'
                              : 'bg-green-600 hover:bg-green-700'
                          }`}
                          disabled={loadingRegister[user.id]}
                        >
                          {loadingRegister[user.id] ? 'Inscrevendo...' : 'Inscrever'}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className='flex justify-end mt-4'>
                    <button
                      onClick={closeAvailableUsersModal}
                      className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700'
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LeagueProfile;
