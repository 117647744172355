// Example definition of useExpanded hook
import { useState, useEffect } from 'react';

function useExpanded(screenWidthThreshold) {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setExpanded(window.innerWidth > screenWidthThreshold);
        };

        // Initial call to handleResize
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidthThreshold]);

    return expanded;
}

export default useExpanded;
